import { Box, Grid, IconButton, Image } from '@chakra-ui/react';
import { ACCESS_CLAIM } from "@mondstein/api";
import { usePermissions } from '@mondstein/hooks';
import { Card, PRIMARY_COLOR, TertiaryBtn } from '@mondstein/ui';
import React, { useMemo } from 'react';
import { Grid as GridIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Outlet, useMatch } from "react-router";
import { Link, Route, Routes } from 'react-router-dom';
import Container from "../../../modules/base/components/Container/Container";
import { FullScreenLoader } from "../../../modules/base/components/FullScreenLoader";
import SimpleList, { SimpleListItem } from "../../../modules/base/components/SimpleList/SimpleList";
import { usePageTitle } from "../../../modules/base/hooks/use-page-title";
import { useBreadcrumbs } from "../../../modules/base/hooks/useBreadcrumbs";
import AppLayout from '../../../modules/base/layouts/AppLayout';
import NotFoundPage from "../../../modules/base/pages/NotFoundPage";
import { ContentSizes } from "../../../modules/base/types/ContentSizes";
import GameCreatePage from "../GameCreatePage/GameCreatePage";
import GameEditPage from '../GameEditPage/GameEditPage';
import GamesListPage from '../GamesListPage/GamesListPage';
import PublisherCreatePage from "../PublisherCreatePage/PublisherCreatePage";
import PublisherEditPage from "../PublisherEditPage/PublisherEditPage";
import PublisherListPage from "../PublisherListPage/PublisherListPage";

export function Main() {
    const [t] = useTranslation();
    const isPublisherRoute = useMatch( '/studio/publisher' );
    const isGamesRoute = useMatch( '/studio/games' );
    usePageTitle( t( 'general.studio' ) );

    const { isLoading, isAllowed } = usePermissions( [ACCESS_CLAIM.CAN_ACCESS_STUDIO] );

    const list: SimpleListItem[] = useMemo( () => [
        {
            id: 'publisher_list',
            to: '/studio/publisher',
            label: t( 'general.publisher_list' ),
            active: !!isPublisherRoute ?? false
        },
        {
            id: 'game_list',
            to: '/studio/games',
            label: t( 'general.game_list' ),
            active: !!isGamesRoute ?? false
        },
    ], [isPublisherRoute, isGamesRoute] );

    useBreadcrumbs( [
        { text: t( 'general.studio' ), url: '/studio' },
    ] );

    if (isLoading) {
        return <FullScreenLoader/>;
    }

    if (!isAllowed) {
        return <NotFoundPage/>;
    }

    return (<AppLayout>
        <Container size={ ContentSizes.WIDE } showBreadcumbs={ false }>
            <Grid templateColumns="3fr 9fr" gap={ 6 }>
                <div>
                    <Box position={ 'sticky' } top={ 24 }>
                        <Card title={ 'Studio' }
                            actions={ <Link to={ `/` }>
                                <TertiaryBtn>
                                    { t( 'general.store' ) }
                                </TertiaryBtn>
                            </Link> }
                            avatar={ <Link to={ `/studio` }>
                                <IconButton size={ 'lg' }
                                    colorScheme={ PRIMARY_COLOR }
                                    variant={ 'ghost' }
                                    rounded={ 'full' }
                                    aria-label={ 'Back' }>
                                    <GridIcon/>
                                </IconButton>
                            </Link> }>
                            <SimpleList list={ list }/>
                        </Card>
                    </Box>
                </div>
                <Box>
                    <Outlet/>
                </Box>
            </Grid>
        </Container>
    </AppLayout>);
}

export default Main;
