import { setBackend } from "@mondstein/api";
import React, { Suspense } from 'react';
import { Toaster } from "react-hot-toast";
import { RouteMap } from "./components/route-map";
import FooterBar from './modules/base/components/FooterBar';
import { FullScreenLoader } from './modules/base/components/FullScreenLoader';
import { routes } from './router/routes';

export const App = () => {
    setBackend( process.env.REACT_APP_BACKEND ?? 'https://dev-store-backend.mondstein.io', process.env.REACT_APP_BACKEND_API_KEY ?? 'jJBsEpDa8dGLLPj74JcBTN21' );

    return (
        <>
            <Toaster/>
            <Suspense fallback={ <FullScreenLoader/> }>
                <RouteMap routes={ routes }/>
                <FooterBar/>
            </Suspense>
        </>
    )
}

export default App;