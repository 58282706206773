import { Box, Flex, useToast } from '@chakra-ui/react';
import { useAuthenticationApi } from "@mondstein/hooks";
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router";
import { Navigate } from 'react-router-dom';
import { useAuth } from "../../hooks/use-auth";

import RequestResetForm from '../../modules/auth/components/RequestResetForm/RequestResetForm';
import { FullScreenLoader } from "../../modules/base/components/FullScreenLoader";

export type LoginPageProps = {};

export function RequestResetPage({}: LoginPageProps) {
    const [isLoading, setLoading] = useState( false );
    const toast = useToast();
    const [t] = useTranslation();
    const navigate = useNavigate();
    const { requestPasswordReset } = useAuthenticationApi();
    const { isAuthenticated, isLoading: isAuthLoading } = useAuth();

    const onReset = useCallback( (userName: string) => {
        setLoading( true )
        requestPasswordReset( userName )
            .then( () => {
                toast( {
                    title: t( 'reset.request.success.title' ),
                    description: t( 'reset.request.success.description' ),
                    status: 'success',
                } )
                navigate( '/' );
            } )
            .catch( () => {
                toast( {
                    title: t( 'reset.request.fail.title' ),
                    description: t( 'reset.request.fail.description' ),
                    status: 'error',
                } )
            } )
            .finally( () => {
                setLoading( false );
            } )
    }, [] );

    if (isAuthLoading) {
        return <FullScreenLoader/>;
    }

    if (isAuthenticated) {
        return <Navigate to={ '/' } replace/>;
    }

    return (<Flex w="100vw" h="100vh" align="center" justifyContent="center">
        <Box maxW={ 400 } w={ '100%' }>
            <RequestResetForm onReset={ onReset } isLoading={ isLoading }/>
        </Box>
    </Flex>);
}

