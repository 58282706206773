import React, { useEffect } from 'react';
import { RouteProps, useNavigate } from 'react-router';
import { useAuth } from "../../../hooks/use-auth";
import { FullScreenLoader } from "./FullScreenLoader";

export type PrivateRouteProps = RouteProps;

/**
 * This component is used, if you have a route which requires the user to be authenticated.
 * If the user is not authenticated, the route redirects to the login screen
 */
export const PrivateRoute = ({ element, ...rest }: PrivateRouteProps) => {
    const navigate = useNavigate();
    const { isLoading, isAuthenticated } = useAuth();

    useEffect( () => {
        if (!isAuthenticated) {
            navigate( '/auth/login' );
        }
    }, [isAuthenticated] );

    if(isLoading){
        return <FullScreenLoader/>;
    }
    return <>{ element }</>
}
