import { Box, Button, Divider, Flex, Menu, MenuButton, MenuGroup, MenuItem, MenuList, } from '@chakra-ui/react';
import { ACCESS_CLAIM } from "@mondstein/api";
import { useAuthenticationApi, useBrandColor, usePermissions } from "@mondstein/hooks";
import { compose } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { User } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import LoginSignupWidget from '../../../auth/components/LoginSignupWidget/LoginSignupWidget';
import { isAuthenticatedState } from '../../../auth/state/is-authenticated';
import { withUserInfo, WithUserInfoProps } from '../../hoc/with-user-info';

export type UserMenuProps = WithUserInfoProps & {
    forceOpen?: boolean;
};

/**
 * Render the user custom menu. From here he can change password, logout and so on
 * @constructor
 */
export function UserMenu({ userInfo, forceOpen }: UserMenuProps) {
    const navigate = useNavigate();
    const [brand] = useBrandColor();
    const [, setAuthenticated] = useRecoilState( isAuthenticatedState );
    const [t] = useTranslation();
    const { doLogout } = useAuthenticationApi();

    const permissions = usePermissions( [ACCESS_CLAIM.CAN_ACCESS_ADMIN, ACCESS_CLAIM.CAN_ACCESS_STUDIO] );
    const hasAdminPermission = useMemo( () => permissions[ACCESS_CLAIM.CAN_ACCESS_ADMIN], [permissions] )
    const hasPublisherPermission = useMemo( () => permissions[ACCESS_CLAIM.CAN_ACCESS_STUDIO], [permissions] )

    const onLogoutClicked = useCallback( () => {
        doLogout()
            .then( () => {
                setAuthenticated( false );
                navigate( '/' );
            } )
    }, [] );

    if (!userInfo) {
        return <LoginSignupWidget/>
    }

    return (
        <Menu isOpen={ forceOpen }>
            <MenuButton as={ Button } variant={ 'outline' } colorScheme={ brand }>
                <Flex alignItems={ 'center' }>
                    <Box mr={ 2 }><User/></Box> { userInfo.username }
                </Flex>
            </MenuButton>
            <MenuList>

                { hasAdminPermission && <>
                    <MenuGroup title="Admin">
                        <MenuItem
                            as={ Link }
                            to="/admin">
                            { t( 'nav.admin.title' ) }
                        </MenuItem>
                    </MenuGroup>
                    <Divider/>
                </> }

                { hasPublisherPermission && <>
                    <MenuGroup title="Publisher">
                        <MenuItem
                            as={ Link }
                            to="/studio">
                            { t( 'nav.studio.title' ) }
                        </MenuItem>
                        <MenuItem
                            as={ Link }
                            to="/studio/games">
                            { t( 'general.games_list' ) }
                        </MenuItem>
                    </MenuGroup>
                    <Divider/>
                </> }

                <MenuGroup title="User">
                    <MenuItem
                        as={ Link }
                        to="/store/checkout">
                        { t( 'nav.purchase_list.title' ) }
                    </MenuItem>

                    <MenuItem
                        as={ Link }
                        to="/users/me/devices">
                        { t( 'nav.device_list.title' ) }
                    </MenuItem>

                    <MenuItem
                        as={ Link }
                        to="/users/me/settings">
                        { t( 'general.settings' ) }
                    </MenuItem>
                </MenuGroup>
                <Divider/>
                <MenuGroup title="Profile">
                    <MenuItem
                        as={ Link }
                        to="/users/me">
                        { t( 'nav.profile.show' ) }
                    </MenuItem>
                    <Divider/>
                    <MenuItem
                        color={ 'red.400' }
                        onClick={ onLogoutClicked }>
                        { t( 'general.log_out' ) }
                    </MenuItem>
                </MenuGroup>
            </MenuList>
        </Menu>
    );
}

export default compose( withUserInfo )( UserMenu );
