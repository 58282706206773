import { UserInfoData } from "@mondstein/api";
import { useUserApi } from "@mondstein/hooks";
import React, { ComponentType, useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { FullScreenLoader } from '../../base/components/FullScreenLoader';
import { useMounted } from '../../base/hooks/use-mounted';
import { userInfoState } from '../state/user-info-state';

export type WithUserInfoProps = {
    /** User info object */
    userInfo: UserInfoData | null;

    /**
     * Function to refresh the user data with the data on the API
     */
    refreshUserInfo: Function,
}

export function withUserInfo<P = {}>(Component: ComponentType<P>): React.FC<P> {
    return function withUserInfoContainer(props) {
        const [userInfo, setUserInfo] = useRecoilState( userInfoState );
        const [isLoading, setLoading] = useState( userInfo === null );
        const isMounted = useMounted();
        const { getUser } = useUserApi();

        const loadData = useCallback( () => {
            setLoading( true );
            getUser()
                .then( (data) => {
                    if (!isMounted) {
                        return;
                    }
                    setUserInfo( data )
                } )
                .catch( () => {
                    if (!isMounted) {
                        return;
                    }
                    setUserInfo( null )
                } )
                .finally( () => {
                    setLoading( false );
                } );
        }, [] );

        const refreshUserInfo = useCallback( () => {
            loadData();
        }, [] );

        useEffect( () => {
            if (!userInfo) {
                loadData();
            }
        }, [] )

        if (isLoading) {
            return <FullScreenLoader/>;
        }

        return <Component key="content" { ...props } userInfo={ userInfo } refreshUserInfo={ refreshUserInfo }/>;
    }
}
