import { Avatar, Heading } from '@chakra-ui/react';
import { Card, Box, Flex, } from '@mondstein/ui';
import React from 'react';
import { useFormattedDate } from '../../../base/hooks/useFormattedDate';

export type PublisherCardProps = {
    avatarUrl?: string | null;
    name: string;
    createdAt: Date;
}

/**
 * Shows infos about the publisher of a game
 * @constructor
 */
export const PublisherCard = ({ name, createdAt, avatarUrl }: PublisherCardProps) => {
    const date = useFormattedDate( createdAt );
    return <Card>
        <Flex justifyContent="space-between" alignItems="center">
            <Heading as="h3" size="md">{ name }</Heading>
            { avatarUrl ? <Avatar name={ name } src={ avatarUrl }/> : <Avatar name={ name }/> }
        </Flex>

        <Box>
            { date }
        </Box>
    </Card>
}

export default PublisherCard;
