import { usePublisherApi } from "@mondstein/hooks";
import { Card, TertiaryBtn } from "@mondstein/ui";
import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAsyncData } from "../../../modules/base/hooks/use-async-data";
import { useBreadcrumbs } from "../../../modules/base/hooks/useBreadcrumbs";
import PublisherGameList from "../../../modules/studio/components/publisher-game-list/publisher-game-list";

export type GamesListPageProps = {}
export type GamesListPageParams = {
    id?: string;
}

export default function GamesListPage({}: GamesListPageProps) {
    const [t] = useTranslation();
    const { fetchList } = usePublisherApi();
    const [isPublishersLoading, publisherList] = useAsyncData( () => fetchList() );

    useBreadcrumbs( [
        { text: t( 'general.studio' ), url: '/studio' },
        { text: t( 'nav.games_list.title' ), url: '/studio/games' },
    ] );

    return (<div className="GamesListPage">
        <Card title={ t( 'general.games_list' ) } actions={ <Link to={ '/studio/games/new' }>
            <TertiaryBtn>
                { t( 'general.new_game.cta' ) }
            </TertiaryBtn>
        </Link> }>
            { publisherList?.map( (publisher) => (<PublisherGameList key={ publisher.id } publisher={ publisher }/>) ) }
        </Card>
    </div>);
}
