import { Card, TertiaryBtn } from "@mondstein/ui";
import React, { useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FullScreenLoader } from "../../../modules/base/components/FullScreenLoader";
import SimpleList from "../../../modules/base/components/SimpleList/SimpleList";
import { useBreadcrumbs } from "../../../modules/base/hooks/useBreadcrumbs";
import { usePublisherList } from "../../../modules/studio/hooks/use-publisher-list";

export type PublisherListPageProps = {}

export default function PublisherListPage({}: PublisherListPageProps) {
    const { isLoading, list } = usePublisherList();
    const [t] = useTranslation();

    useBreadcrumbs( [
        { text: t( 'general.studio' ), url: '/studio' },
        { text: t( 'nav.publisher_list.title' ), url: '/studio/publisher' },
    ] );

    const mappedList = useMemo( () => {
        return list.map( (item) => ({
            id: item.id,
            label: item.name,
            to: `/studio/publisher/${ item.id }`
        }) );
    }, [list] );

    if (isLoading) {
        return <FullScreenLoader/>
    }

    return (<>
        <Card title={ t( 'general.publisher_list' ) } actions={ <Link to={ '/studio/publisher/create' }>
            <TertiaryBtn>
                Create Publisher
            </TertiaryBtn>
        </Link> }>
            <SimpleList list={ mappedList }/>
        </Card>
    </>);
}
