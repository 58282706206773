import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import Container from "../components/Container/Container";
import Error404 from "../components/Error404/Error404";
import PageHeading from "../components/PageHeading/PageHeading";
import AppLayout from '../layouts/AppLayout';
import { breadcrumbState } from '../state/breadcrumbs';
import { ContentSizes } from "../types/ContentSizes";

/**
 * Page to render a 404 state
 * @constructor
 */
export default function NotFoundPage() {
    const setBreadcrumbs = useSetRecoilState( breadcrumbState );

    useEffect( () => {
        setBreadcrumbs( [] );
    }, [] );

    return (
        <AppLayout>
            <Container size={ ContentSizes.NARROW }>
                <PageHeading title={ 'Ooopps... Page not found' } backLink={'/'} />
                { /*  @ts-ignore */ }
                <Error404/>
            </Container>
        </AppLayout>
    );
}
