import { HStack, VStack } from "@chakra-ui/react";
import { deleteDevice, getDeviceOwner, removeDeviceOwner, UserModelData } from "@mondstein/api";
import { useDeviceApi, useErrorHandler, useToast } from "@mondstein/hooks";
import { BackButton, Card, ConfirmButton, Stats } from "@mondstein/ui";
import * as React from "react";
import { useCallback, useEffect } from "react";
import { Edit } from "react-feather";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import Error404 from "../../../../modules/base/components/Error404/Error404";
import { FullScreenLoader } from "../../../../modules/base/components/FullScreenLoader";
import { useAsyncData, useAsyncLoader } from "../../../../modules/base/hooks/use-async-data";
import { useBreadcrumbs } from "../../../../modules/base/hooks/useBreadcrumbs";
import { createDateFormatter } from "../../../../utils/date-formatter";

export type DeviceDetailPageParams = {
    id: string;
}

export const DeviceDetailPage = () => {
    const [t] = useTranslation();
    const { id } = useParams<DeviceDetailPageParams>();
    const toast = useToast();
    const navigate = useNavigate();
    const { getDeviceInfo } = useDeviceApi();
    const errorHandler = useErrorHandler();
    const [isLoading, deviceInfo, refreshDeviceInfo, deviceLoadError] = useAsyncData( () => {
        if (!id) {
            return null;
        }
        return getDeviceInfo( id );
    }, [id], errorHandler );
    const [isLoadingOwner, deviceOwnerLoader, deviceOwner] = useAsyncLoader<UserModelData, { deviceUUID?: string }>( ({ deviceUUID }) => {
        if (!deviceUUID) {
            return null;
        }
        return getDeviceOwner( deviceUUID )
    }, [deviceInfo] );

    const formatter = createDateFormatter();

    useBreadcrumbs( [
        { text: t( 'general.admin' ), url: '/admin' },
        { text: t( 'admin.devices.list' ), url: '/admin/devices' },
        { text: deviceInfo?.name ?? '', url: `/admin/devices/${ deviceInfo?.id ?? '' }` },
    ], [deviceInfo] );

    useEffect( () => {
        if (!deviceInfo) {
            return;
        }

        deviceOwnerLoader( { deviceUUID: deviceInfo?.deviceUuid } );
    }, [deviceInfo] );

    const onDeleteConfirmed = useCallback( () => {
        if (!deviceInfo) {
            return;
        }
        deleteDevice( deviceInfo.deviceUuid )
            .then( () => {
                toast.success( t( 'device.successfully_deleted' ) );
                navigate( '/admin/devices' );
            } )
            .catch( errorHandler );
    }, [deviceInfo] );

    const onRemoveOwnerConfirmed = () => {
        if (!deviceInfo) {
            return;
        }
        removeDeviceOwner( deviceInfo.deviceUuid )
            .then( () => {
                toast.success( t( 'device.successfully_deleted' ) );
                refreshDeviceInfo();
            } )
            .catch( errorHandler );
    }

    if (deviceLoadError) {
        return <Error404/>;
    }

    if (isLoading || isLoadingOwner || !deviceInfo) {
        return <FullScreenLoader/>
    }


    return <VStack spacing={ 4 }>
        <Card title={ `#${ deviceInfo.id } - ${ deviceInfo.name }` }
            avatar={ <Link to={ '/admin/devices' }><BackButton/></Link> }
            actions={ <Link to={ `/admin/devices/${ deviceInfo?.id }/edit` }><Edit/></Link> }
        >
            <VStack spacing={ 4 } alignItems={ 'flex-start' }>
                { deviceOwner && <Stats title={ t( 'general.owner' ) }
                    content={ `#${ deviceOwner?.id } ${ deviceOwner?.username } - ${ deviceOwner?.email }` }/> }
                { !deviceOwner && <Stats title={ t( 'general.owner' ) } content={ `No owner yet` }/> }
                <Stats title={ t( 'general.created_at' ) } content={ formatter( deviceInfo.created_at ) }/>
                <Stats title={ t( 'general.updated_at' ) } content={ formatter( deviceInfo.updated_at ) }/>
                <Stats title={ t( 'general.last_seen_at' ) }
                    content={ deviceInfo.lastSeen ? formatter( deviceInfo.lastSeen ) : '-' }/>
                <Stats title={ t( 'general.device_uid' ) } content={ deviceInfo.deviceUuid }/>
            </VStack>
        </Card>

        <Card title={ t( 'general.actions' ) }>
            <HStack spacing={ 4 }>
                <ConfirmButton title={ 'Delete Device' } label={ 'Delete Device' } onConfirm={ onDeleteConfirmed }/>
                <ConfirmButton title={ 'Remove Owner' } label={ 'Remove Owner' }
                    disabled={ !deviceOwner }
                    onConfirm={ onRemoveOwnerConfirmed }/>
            </HStack>
        </Card>
    </VStack>;
}