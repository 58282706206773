import React from 'react';
import { Outlet } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Container from "../../../modules/base/components/Container/Container";
import AppLayout from "../../../modules/base/layouts/AppLayout";
import { contentSizeState } from "../../../modules/base/state/content-size";

export type MainProps = {}

export default function Main({}: MainProps) {
    const contentSize = useRecoilValue( contentSizeState );

    return (<AppLayout>
        <Container size={ contentSize } showBreadcumbs={ true }>
            <Outlet/>
        </Container>
    </AppLayout>);
}
