import { useCallback, useState } from "react";

export const useSeries = (initialSeries: number[] = [], maxEntries = 50): [number[], (val: number) => void] => {
    const [serie, setSerie] = useState( initialSeries );

    const add = useCallback( (value: number) => {
        setSerie( [...serie.slice( -maxEntries ), value] );
    }, [setSerie, serie] );

    return [serie, add];
}