import { Box, Text } from '@chakra-ui/react';
import { Breadcrumbs, Header } from '@mondstein/ui';
import React from 'react';
import { Link } from "react-router-dom";
import UserMenu from '../../user/components/UserMenu/UserMenu';
import { useBreadcrumbs } from "../hooks/useBreadcrumbs";

export type HeaderBarProps = {
    /**
     * Set max width of header
     */
    maxWidth?: string;
}

/**
 * Header bar containing navigation entries
 */
export const HeaderBar = ({ maxWidth }: HeaderBarProps) => {
    return <Header maxWidth={ maxWidth }
        end={ <UserMenu/> }>
        <Box>
            <Link to={ '/' }>
                <Text
                    letterSpacing={ '4px' }
                    fontSize={ 24 }
                    fontWeight={ 'bold' }
                    fontFamily={ 'Blanka Regular' }>MONDSTEIN</Text>
            </Link>
        </Box>
    </Header>
}
