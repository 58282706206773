import React from 'react';
import { Outlet, Route } from 'react-router-dom';
import AppLayout from '../../../modules/base/layouts/AppLayout';
import DetailPage from '../DetailPage/DetailPage';
import OverviewPage from '../OverviewPage';

export type MainProps = {}

export default function Main({}: MainProps) {
    return (<AppLayout>
        <Outlet/>
    </AppLayout>);
}
