import { Text } from "@chakra-ui/react";
import { DeviceModelData } from "@mondstein/api";
import { useDeviceApi } from "@mondstein/hooks";
import { Card, List, ListItem, TertiaryBtn } from "@mondstein/ui";
import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FullScreenLoader } from "../../../../modules/base/components/FullScreenLoader";
import { useAsyncData } from "../../../../modules/base/hooks/use-async-data";
import { useBreadcrumbs } from "../../../../modules/base/hooks/useBreadcrumbs";

export type DeviceListProps = {};

export default function DevicesListPage({}: DeviceListProps) {
    const [t] = useTranslation();
    const { getAllDevices } = useDeviceApi();
    const [isLoading, list, refresh] = useAsyncData<DeviceModelData[]>( () => getAllDevices() );

    useBreadcrumbs( [
        { text: t( 'general.admin' ), url: '/admin' },
        { text: t( 'admin.devices.list' ), url: '/admin/devices' },
    ] );

    if (isLoading) {
        return <FullScreenLoader/>
    }

    return (<Card title={ t( 'general.devices_list' ) }
        actions={ <Link to={ '/admin/devices/new' }>
            <TertiaryBtn>
                { t( 'general.register_new_device' ) }
            </TertiaryBtn>
        </Link> }>

        <List>
            { (list ?? []).map( (item: DeviceModelData) => (<ListItem
                path={ `/admin/devices/${ item.id }` }
                secondary={ <Text variant={ 'xs' }>{ item.deviceUuid }</Text> }
                key={ item.id }>
                { item.name }
            </ListItem>) ) }
        </List>
    </Card>);
}
