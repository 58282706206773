import { Stack } from '@chakra-ui/react';
import { PurchaseModelData } from "@mondstein/api";
import React from 'react';
import PurchaseListItem from '../PurchaseListItem/PurchaseListItem';

export type PurchaseListProps = {
    purchases: PurchaseModelData[];
}

export default function PurchaseList({ purchases }: PurchaseListProps) {
    return (<Stack spacing={ 8 } className="PurchaseList">
        { purchases.map( (purchase) => <PurchaseListItem key={ purchase.id } purchaseModel={ purchase }/> ) }
    </Stack>);
}
