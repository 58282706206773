import { Box, Flex, Heading, Img } from '@chakra-ui/react';
import { PurchaseModelData } from "@mondstein/api";
import React from 'react';
import { Link } from 'react-router-dom';
import { useFormattedDateTime } from '../../../base/hooks/useFormattedDateTime';
import { useFormattedPrice } from '../../../base/hooks/useFormattedPrice';
import { useTransformedPurchaseModelData } from '../../hooks/use-transformed-purchase-model-data';

export type PurchaseListItemProps = {
    purchaseModel: PurchaseModelData;
}

export default function PurchaseListItem({ purchaseModel }: PurchaseListItemProps) {
    const { title, image } = useTransformedPurchaseModelData( purchaseModel );
    const formattedPrice = useFormattedPrice( purchaseModel.price, purchaseModel.currency );
    const formattedPurchaseDate = useFormattedDateTime( purchaseModel.createdAt );

    return (<Link to={ `/store/checkout/purchase/${ purchaseModel.id }` }>
        <Flex className="PurchaseListItem" justifyContent={ 'space-between' } alignItems={ 'center' }>
            <Flex alignItems={ 'center' }>
                <Img src={ image } height={ 20 } width={ 40 } objectFit={ 'cover' }/>
                <Heading variant={ 'h3' } size={ 'lg' } ml={ 3 }>
                    { title }
                </Heading>
            </Flex>
            <Box>{ formattedPurchaseDate }</Box>
            <Box>{ formattedPrice }</Box>
        </Flex>
    </Link>);
}
