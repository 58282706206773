import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { PublisherModelData } from "@mondstein/api";
import { usePublisherApi } from "@mondstein/hooks";
import { BackButton, Card, PRIMARY_COLOR } from "@mondstein/ui";
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Link, useParams } from "react-router-dom";
import { FullScreenLoader } from "../../../modules/base/components/FullScreenLoader";
import SimpleList from "../../../modules/base/components/SimpleList/SimpleList";
import { useAsyncData } from "../../../modules/base/hooks/use-async-data";
import { useBreadcrumbs } from "../../../modules/base/hooks/useBreadcrumbs";
import PublisherEditForm from "../../../modules/studio/components/PublisherEditForm/PublisherEditForm";

export type EditPublisherPageProps = {}
export type EditPublisherPageParams = { id: string }

export default function PublisherEditPage({}: EditPublisherPageProps) {
    const { id } = useParams<EditPublisherPageParams>();
    const [t] = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState( false );
    const { update, load, remove, loadGames } = usePublisherApi();
    const [publisher, setPublisher] = useState<PublisherModelData | null>( null )
    const [isFetchingGames, fetchedGames] = useAsyncData( () => {
        if (!id) {
            return null
        }
        return loadGames( parseInt( id ) )
    }, [id] );


    useBreadcrumbs( [
        { text: t( 'general.studio' ), url: '/studio' },
        { text: t( 'nav.publisher_list.title' ), url: '/studio/publisher' },
        !!publisher && { text: publisher?.name, url: `/studio/publisher/${ id }` },
    ], [publisher] );

    useEffect( () => {
        if (!id) {
            return;
        }

        setIsLoading( true );
        load( parseInt( id ) )
            .then( setPublisher )
            .finally( () => setIsLoading( false ) );
    }, [id] );

    const onSave = useCallback( (pub: PublisherModelData) => {
        return update( pub )
    }, [] );

    const onRemove = useCallback( (pub: PublisherModelData) => {
        return remove( pub.id )
            .then( () => {
                navigate( '/studio/publisher' )
            } );
    }, [] )

    if (isLoading || !publisher) {
        return <FullScreenLoader/>
    }

    const GamesList = () => {
        if (isFetchingGames || !fetchedGames) {
            return <FullScreenLoader/>
        }
        return <SimpleList list={ fetchedGames.map( (game) => ({
            id: game.id,
            label: game.name,
            to: `/studio/games/${ game.id }`
        }) ) }/>
    }

    return (<Tabs variant="soft-rounded" colorScheme={ PRIMARY_COLOR }>
        <TabList>
            <Tab>{ t( 'general.publisher' ) }</Tab>
            <Tab>{ t( 'general.games' ) }</Tab>
        </TabList>
        <TabPanels>
            <TabPanel>
                <Card title={ publisher.name } avatar={ <Link to={ `/studio/publisher` }><BackButton/></Link> }>
                    <PublisherEditForm onSave={ onSave } onRemove={ onRemove } publisher={ publisher }/>
                </Card>
            </TabPanel>
            <TabPanel>
                <Card title={ t( 'general.games' ) }>
                    <GamesList/>
                </Card>
            </TabPanel>
        </TabPanels>
    </Tabs>);
}
