import { useCallback, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { pageTitleState } from "../state/page-title-state";

export const usePageTitle = (title: string | null = null) => {
    const setPageTitle = useSetRecoilState( pageTitleState );

    useEffect( () => {
        setPageTitle( { title } );
        return () => setPageTitle( { title: null } );
    }, [title] );

    const setTitle = useCallback( (t: string) => {
        setPageTitle( { title: t } );
    }, [] );

    return setTitle;
}