import * as React from 'react';
import { CustomRoute } from '../../router/routes';
import DetailPage from "./DetailPage/DetailPage";
import Main from './Main/Main';
import OverviewPage from "./OverviewPage";

export const gamesRoutes: CustomRoute[] = [
    {
        path: '/store/games',
        element: <Main/>,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '/store/games/:id',
                element: <DetailPage/>
            },
            {
                path: '/store/games',
                element: <OverviewPage/>
            },
        ]
    },
];
