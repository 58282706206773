import { Box, Flex, useToast } from '@chakra-ui/react';
import { useCheckoutApi } from "@mondstein/hooks";
import { PrimaryBtn } from "@mondstein/ui";
import { compose } from 'lodash/fp';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { EmptyState } from '../../../modules/base/components/EmptyState/EmptyState';
import { FullScreenLoader } from '../../../modules/base/components/FullScreenLoader';
import { useBreadcrumbs } from '../../../modules/base/hooks/useBreadcrumbs';
import CheckoutSummary from '../../../modules/checkout/components/checkout-summary/checkout-summary';
import { useGameData } from '../../../modules/games/hooks/use-game-data';
import { useIsGamePurchased } from '../../../modules/games/hooks/use-is-game-purchased';

export type CheckoutPageParams = { id: string; }

/**
 * Page showing the checkout state for the current user
 * @constructor
 */
export function CheckoutPage() {
    const [t] = useTranslation();
    const { id } = useParams<CheckoutPageParams>();
    const isCartValid = !!id;
    const { gameData, isLoading } = useGameData( id ? parseInt( id ) : null );
    const [isPurchasing, setIsPurchasing] = useState( false );
    const toast = useToast();
    const navigate = useNavigate();
    const [isPurchased, isPurchaseStateLoading] = useIsGamePurchased( id ? parseInt( id ) : null );
    const { checkoutGame } = useCheckoutApi();

    useBreadcrumbs( [
        { text: t( 'general.store' ), url: '/' },
        { text: t( 'general.games' ), url: `/store/games` },
        { text: gameData?.name || '', url: `/store/games/${ id }` },
        { text: t( 'general.checkout' ), url: `/store/checkout/${ id }` },
    ], [gameData, id] );

    const onCheckoutClicked = useCallback( () => {
        if (!gameData) {
            return;
        }

        setIsPurchasing( true );

        checkoutGame( gameData )
            .then( (purchaseId) => {
                toast( {
                    title: 'Purchase Complete',
                    description: `You successfully purchased ${ gameData.name }`,
                    status: 'success',
                } );
                navigate( `/store/checkout/purchase/${ purchaseId }` );
            } )
            .catch( () => {
                toast( {
                    title: 'Purchase Failed',
                    description: `Purchase failed due to unknown error`,
                    status: 'error',
                } );
            } )
            .finally( () => {
                setIsPurchasing( false );
            } );
    }, [gameData] );

    const renderEmptyCart = () => {
        return <EmptyState title={ 'Your cart is empty' } link={ '/store' } linkLabel={ 'Back to store' }
            illustration={ 'empty' }/>
    }

    const renderCart = () => {
        if (!gameData) {
            return null;
        }

        return <CheckoutSummary title={ gameData.name }
            thumbnail={ gameData.primaryVisual }
            price={ gameData.price }
            currency={ gameData.currency }/>
    }

    const renderContent = () => {
        return <Box p={ 2 }>
            { !isCartValid && renderEmptyCart() }
            { isCartValid && renderCart() }

            <Flex justifyContent={ 'flex-end' } mt={ 4 }>
                <PrimaryBtn
                    onClick={ onCheckoutClicked }
                    isLoading={ isPurchasing || isPurchaseStateLoading }
                    disabled={ isPurchasing || isPurchased }>
                    { t( isPurchased ? 'store.item_already_purchased' : 'generale.purchase' ) }
                </PrimaryBtn>
            </Flex>
        </Box>
    }

    return (<>
        { isLoading ? <FullScreenLoader/> : renderContent() }
    </>);
}

export default compose()( CheckoutPage );
