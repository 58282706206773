import { ThemeProvider } from '@mondstein/ui';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import App from './App';
import './i18n';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider>
            <HashRouter>
                <RecoilRoot>
                    <App/>
                </RecoilRoot>
            </HashRouter>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById( 'root' ),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
