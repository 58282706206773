import { Box, Flex } from '@chakra-ui/react';
import { Breadcrumbs } from '@mondstein/ui';
import React, { PropsWithChildren, ReactElement, useMemo } from 'react';
import { Link } from "react-router-dom";
import { useRecoilValue } from 'recoil';
import { HeaderBar } from '../components/HeaderBar';
import { useBreadcrumbs } from "../hooks/useBreadcrumbs";
import { ContentSizeState, contentSizeState } from '../state/content-size';
import { ContentSizeDefinition, ContentSizes } from '../types/ContentSizes';


type AppLayoutProps = PropsWithChildren<{
    /** Defines the width of the content */
    contentSize?: ContentSizes;

    /**
     * Customize the header with custom component
     */
    header?: ReactElement;
}>;


/**
 * Layout component surrounding the complete view
 */
export const AppLayout = ({ children, header }: AppLayoutProps) => {
    const contentSize = useRecoilValue<ContentSizeState>( contentSizeState );
    const [breadcrumbs] = useBreadcrumbs();

    const { padding } = useMemo( () => {
        if (!contentSize) {
            return ContentSizeDefinition[ContentSizes.DEFAULT];
        }
        return ContentSizeDefinition[contentSize];
    }, [contentSize] );

    const renderHeader = () => {
        if (header) {
            return header;
        }

        return <HeaderBar maxWidth={ ContentSizeDefinition[ContentSizes.WIDE].width }/>
    }

    return (
        <Flex direction="column" alignItems="center" justifyContent="center">
            { renderHeader() }
            <Box w={ '100%' }
                paddingTop={ 2 }
                paddingBottom={ 2 }
                pl={ padding }
                pr={ padding }
                marginLeft={ 'auto' }
                marginRight={ 'auto' }
                marginTop={ 8 }>

                { children }
            </Box>
        </Flex>
    );
}

export default AppLayout;
