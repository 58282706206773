import { Button, Flex, FormControl, FormLabel, Input } from '@chakra-ui/react';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageHeading from "../../../base/components/PageHeading/PageHeading";

export type SignupFormProps = {
    /**
     * Callback when the login btn is clicked
     */
    onSignup: (username: string, email: string) => void,

    /** define if the request is pending */
    isLoading?: boolean
};

export default function SignupForm({ onSignup, isLoading }: SignupFormProps) {
    const [userName, setUserName] = useState( '' );
    const [email, setEmail] = useState( '' );
    const [t] = useTranslation()

    const onSignupClicked = useCallback( () => {
        onSignup( userName, email )
    }, [userName, email] );

    const isSubmittable = useMemo( () => {
        return userName && userName.length > 3 && email?.length > 6;
    }, [userName, email] );

    return (<form>
        <PageHeading backLink={ '/auth/login' } title={ t( 'general.sign_up' ) }/>
        <FormControl mt={ 8 }>
            <FormLabel>{ t( 'general.email' ) }</FormLabel>
            <Input value={ email }
                   onChange={ (e) => setEmail( e.target.value ) }
                   type="email"
                   placeholder={ t( 'general.email.placeholder' ) } size="lg"/>
        </FormControl>

        <FormControl mt={ 4 }>
            <FormLabel>{ t( 'general.username' ) }</FormLabel>
            <Input value={ userName }
                   onChange={ (e) => setUserName( e.target.value ) }
                   size="lg"
                   placeholder={ t( 'general.username.placeholder' ) }/>
        </FormControl>

        <Flex justifyContent="flex-end" mt={ 4 }>
            <Button isDisabled={ !isSubmittable }
                    isLoading={ isLoading }
                    onClick={ onSignupClicked }>
                { t( 'general.sign_up' ) }
            </Button>
        </Flex>
    </form>);
}
