import {
    Flex,
    FormControl,
    FormLabel,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Select,
} from "@chakra-ui/react";
import { GameModelData, GameStatus, PublisherModelData } from "@mondstein/api";
import { CreateGameData, useFormattedDateTime, useToast } from "@mondstein/hooks";
import {
    Card,
    CardProps,
    Stats,
    TextField,
} from "@mondstein/ui";
import React, { useCallback, useState } from 'react';
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import FormActions from "../../../base/components/FormActions/FormActions";

export type GameCreateFormProps = {
    onSubmit: (data: CreateGameData) => void;
    listOfPublishers: PublisherModelData[];
    game?: GameModelData | null;
    cardProps?: CardProps
}

export default function GameEditForm({ onSubmit, listOfPublishers, game, cardProps }: GameCreateFormProps) {
    const toast = useToast();
    const [t] = useTranslation();
    const [name, setName] = useState( game?.name ?? '' );
    const [binaryUrl, setBinaryUrl] = useState( game?.binaryUrl ?? '' );
    const [genre, setGenre] = useState( game?.genre ?? '' );
    const [primaryVisual, setPrimaryVisual] = useState( game?.primaryVisual ?? '' );
    const [status, setStatus] = useState<GameStatus>( game?.status ?? 'DRAFT' );
    const [description, setDescription] = useState( game?.description ?? '' );
    const [price, setPrice] = useState( (game?.price ?? 0) / 100 );
    const [publisherId, setPublisherId] = useState( game?.publisher?.id ?? null );
    const formattedCreatedAt = useFormattedDateTime( game?.created_at );
    const formattedUpdatedAt = useFormattedDateTime( game?.updated_at );

    const submitClicked = useCallback( () => {
        onSubmit( {
            name,
            description,
            status,
            genre,
            price: price * 100,
            primaryVisual,
            binaryUrl,
            publisherId: publisherId ?? 0,
        } );
    }, [onSubmit, name, description, status, genre, price, primaryVisual, publisherId, binaryUrl] );

    return (<div className="game-edit-form">
        <Card
            { ...cardProps }
            footer={ <FormActions onSubmit={ submitClicked }
                                  isSubmittable={ true }
                                  submitLabel={ t( 'general.save' ) }/> }>

            <Flex justifyContent={ 'space-between' }>
                <Stats title={ t( 'general.created_at' ) } content={ formattedCreatedAt }/>
                <Stats title={ t( 'general.updated_at' ) } content={ formattedUpdatedAt }/>
            </Flex>

            <FormControl mt={ 8 }>
                <FormControl>
                    <TextField label={ t( 'general.name' ) }
                               value={ name }
                               onChange={ (e) => setName( e.target.value ) }
                               placeholder={ '' }/>
                </FormControl>
            </FormControl>

            <FormControl mt={ 8 }>
                <TextField label={ t( 'general.genre' ) }
                           value={ genre }
                           onChange={ (e) => setGenre( e.target.value ) }
                           placeholder={ '' }/>
            </FormControl>

            <FormControl mt={ 8 }>
                <FormLabel>{ t( 'general.status' ) }</FormLabel>
                <Select placeholder="Select option"
                        value={ status }
                        // @ts-ignore
                        onChange={ (e) => setStatus( e.target.value ) }>
                    <option value="DRAFT">Draft</option>
                    <option value="REVIEWING">Reviewing</option>
                    <option value="PUBLISHED">Published</option>
                    <option value="DELETED">Deleted</option>
                </Select>
            </FormControl>

            <FormControl mt={ 8 }>
                <TextField label={ t( 'game.primary_visual_url' ) }
                           value={ primaryVisual }
                           onChange={ (e) => setPrimaryVisual( e.target.value ) }
                           placeholder={ '' }/>
            </FormControl>

            <FormControl mt={ 8 }>
                <FormLabel>{ t( 'general.publisher' ) }</FormLabel>
                <Select placeholder={ t( 'general.publisher_select.placeholder' ) }
                        value={ publisherId ?? '' }
                        onChange={ (e) => setPublisherId( parseInt( e.target.value ) ) }>
                    { listOfPublishers.map( publisher => <option key={ publisher.id }
                                                                 value={ publisher.id }>{ publisher.name }</option> ) }
                </Select>
            </FormControl>

            <FormControl mt={ 8 }>
                <FormLabel>{ t( 'general.description' ) }</FormLabel>

                <ReactQuill
                    theme='snow'
                    value={ description }
                    onChange={ setDescription }
                    style={ { minHeight: '300px' } }
                />
            </FormControl>

            <FormControl mt={ 8 }>
                <FormLabel>{ t( 'general.price' ) }</FormLabel>
                <NumberInput defaultValue={ price } precision={ 2 } step={ 0.2 }
                             onChange={ (string, val) => setPrice( val ) }>
                    <NumberInputField/>
                    <NumberInputStepper>
                        <NumberIncrementStepper/>
                        <NumberDecrementStepper/>
                    </NumberInputStepper>
                </NumberInput>
            </FormControl>
        </Card>
    </div>);
}
