import { Flex, Grid, Heading, IconButton } from '@chakra-ui/react';
import { GameModelData } from "@mondstein/api";
import { useBrandColor } from "@mondstein/hooks";
import { Box } from '@mondstein/ui';
import React from 'react';
import { ArrowRight } from 'react-feather';
import { Link } from 'react-router-dom';
import GameCard from '../GameCard/GameCard';

export type GamesTeaserProps = {
    /** List of games to be teased */
    games: GameModelData[];

    /** Set the limit of games to display */
    limit: number;

    /** Title of the teaser component */
    title: string;
}

/**
 * Shows a teaser for some games with a title. This can be used to promote edit picks or special offers
 * @constructor
 */
export const GamesTeaser = ({ games, limit, title }: GamesTeaserProps) => {
    const [brandColor] = useBrandColor();

    return (<Box>
        <Flex p={ 4 } justifyContent={ 'space-between' }>
            <Heading as="h3" fontSize="lg" mb={ 5 }>{ title }</Heading>
            <IconButton variant={ 'outline' }
                to={ '/store/games' }
                isRound={ true }
                colorScheme={ brandColor }
                aria-label={ 'See all games' }
                as={ Link } icon={ <ArrowRight/> }/>
        </Flex>
        <Grid templateColumns={ `repeat(${ 4 }, 1fr)` } gap={ 3 }>
            { games.slice( 0, limit ).map( (game) => (
                <GameCard
                    is_featured={ false }
                    id={ game.id }
                    visualUrl={ game.primaryVisual }
                    name={ game.name }
                    currency={ game.currency }
                    price={ game.price }
                    description={ game.description }
                    key={ game.id }/>
            ) ) }
        </Grid>
    </Box>)
}

export default GamesTeaser;
