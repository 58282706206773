import { Box, Button, FormControl } from "@chakra-ui/react";
import { PublisherModelData } from "@mondstein/api";
import { TextField } from "@mondstein/ui";
import React, { useCallback, useState } from 'react';
import { useTranslation } from "react-i18next";
import FormActions from "../../../base/components/FormActions/FormActions";

export type PublisherEditFormProps = {
    publisher: PublisherModelData;
    isLoading?: boolean;
    onSave: (publisher: PublisherModelData) => Promise<void>;
    onRemove: (publisher: PublisherModelData) => Promise<void>;
}

export default function PublisherEditForm({ publisher, isLoading, onSave, onRemove }: PublisherEditFormProps) {
    const [editedPublisher, setEditedPublisher] = useState( publisher );
    const [t] = useTranslation();

    const onSubmit = useCallback( () => {
        onSave( editedPublisher )
    }, [editedPublisher, onSave] );

    const onDelete = useCallback( () => {
        onRemove( editedPublisher )
    }, [editedPublisher, onRemove] );

    const onNameChange = useCallback( (name: string) => {
        setEditedPublisher( { ...editedPublisher, name } );
    }, [editedPublisher] );

    return (<Box>
        <FormControl mt={ 8 }>
            <TextField label={ t( 'general.name' ) }
                       value={ editedPublisher.name }
                       onChange={ (e) => onNameChange( e.target.value ) }
                       type="text"
                       placeholder={ t( 'general.name.placeholder' ) }/>
        </FormControl>

        <Box mt={ 4 }>
            <FormActions onSubmit={ onSubmit }
                         isSubmittable={ !!editedPublisher.name }
                         submitLabel={ t( 'general.save' ) }>
                <Button onClick={ onDelete }
                        colorScheme={ 'red' }
                        isLoading={ isLoading }
                        disabled={ publisher.hasGames }>{ t( 'general.delete' ) }</Button>
            </FormActions>
        </Box>
    </Box>);
}
