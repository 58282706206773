import { useMemo } from 'react';
import { CURRENT_LOCALE } from '../../../config/locale';

/**
 * Hook to format a number to a currency for the current locale
 */
export const useFormattedPrice = (cents: number, currency: string) => {
    return useMemo( () => {
        return new Intl.NumberFormat( CURRENT_LOCALE, { style: 'currency', currency: currency } ).format( cents / 100 );
    }, [cents, currency] );
}
