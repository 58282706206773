import * as React from 'react';
import { CustomRoute } from '../../router/routes';
import LoginPage from './LoginPage';
import PasswordRestorePage from './PasswordRestorePage';
import { RequestResetPage } from './RequestResetPage';
import SignupPage from './SignupPage/SignupPage';

export const authRoutes: CustomRoute[] = [
    {
        path: '/auth/login',
        element: <LoginPage/>,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/auth/signup',
        element: <SignupPage/>,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/auth/reset',
        element: <RequestResetPage/>,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/auth/restore/:token',
        element: <PasswordRestorePage/>,
        meta: {
            requiresAuth: false,
        },
    },
];
