import { useEffect, useState } from 'react';

/**
 * Hook to determine if component is still mounted or not. This is used so a component does not update it's state
 * when it's unmounted but the API response comes back late
 */
export const useMounted = () => {
    const [isMounted, setIsMounted] = useState( true );

    useEffect( () => {
        return () => {
            setIsMounted( false );
        };
    }, [] );

    return isMounted;
}
