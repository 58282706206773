import { Button, Flex, Heading, Image } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

export type EmptyStateProps = {
    /** Page title */
    title: string;

    /** Link for the return CTA */
    link: string;

    /** Label for the return CTA */
    linkLabel: string;

    /** Name of the illustration in the `/img/illustrations` folder */
    illustration: string;

    /** Custom CTA element */
    cta?: ReactElement;
};

/**
 * Component to use, if you have an empty sreen
 */
export const EmptyState = ({ title, link, linkLabel, illustration, cta }: EmptyStateProps) => {
    return <Flex direction="column" justifyContent="center" alignItems="center" mt={ 10 }>
        <Heading as="h2" mb={ 10 }>{ title }</Heading>
        <Image src={ `/img/illustrations/${ illustration }.svg` }
            height="400px"/>
        { cta ? cta : <Button as={ Link } to={ link } variant={ 'link' }
            mt={ 6 }>{ linkLabel }</Button> }
    </Flex>;
}
