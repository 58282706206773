import { Heading, Text } from '@chakra-ui/react';
import { useColorShade } from "@mondstein/hooks";
import { compose } from 'lodash/fp';
import React from 'react';
import { withUserInfo, WithUserInfoProps } from '../../hoc/with-user-info';

export type WelcomeMessageProps = WithUserInfoProps;

/**
 * Custom welcome message for the user
 * @constructor
 */
export const WelcomeMessage = ({ userInfo }: WelcomeMessageProps) => {
    const color = useColorShade( 200 );

    if (!userInfo) {
        return null;
    }

    return (<Heading as="h1" size="2xl">
        Welcome <Text as="span" color={ color }>{ userInfo.username }</Text>
    </Heading>)
}

export default compose( withUserInfo )( WelcomeMessage );
