import { Box, Flex } from "@chakra-ui/react";
import React, { ReactElement } from 'react';
import Lottie from 'react-lottie-segments';
import AnimationData from './404.json'

export type Error404Props = {
    cta?: ReactElement;
}

export default function Error404({ cta }: Error404Props) {
    const options = {
        loop: true,
        autoplay: true,
        animationData: AnimationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return <Flex justifyContent={ 'center' } alignItems={ 'center' } flexDirection={ 'column' }>
        {/* @ts-ignore */ }
        <Lottie
            options={ options }
            height={ 420 }
            width={ 591 }
        />

        { cta && <Box mt={ 8 }>
            { cta }
        </Box> }
    </Flex>;
}
