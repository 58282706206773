import { Grid } from '@chakra-ui/react';
import { DeviceModelData } from "@mondstein/api";
import { Card, Stats } from "@mondstein/ui";
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormattedDateTime } from '../../../base/hooks/useFormattedDateTime';

export type DeviceInfoProps = {
    deviceInfo: DeviceModelData;
};

/**
 * Widget showing infos about the current device of the current user
 * @constructor
 */
export default function DeviceInfo({ deviceInfo }: DeviceInfoProps) {
    const formattedLastSeen = useFormattedDateTime( deviceInfo?.lastSeen, 'long' );
    const formattedMetricsTimestamp = useFormattedDateTime( deviceInfo ? new Date( parseInt( deviceInfo?.metricsTimestamp ) ) : null, 'long' );
    const formattedCreatedAt = useFormattedDateTime( deviceInfo?.created_at, 'long' );
    const [t] = useTranslation();

    const formattedUptime = useMemo( () => {
        if (!deviceInfo) {
            return '?';
        }
        return Math.round( parseInt( deviceInfo?.uptime ) / 1000 / 60 / 60 * 100 ) / 100;
    }, [deviceInfo] )


    return (
        <Card title={ t( 'device.info.title' ) }>
            <Grid templateAreas={ 'left right' } gap={ 4 } templateColumns={ '1fr 1fr' }>
                <Stats title={ t( 'device.info.registered_at' ) } content={ formattedCreatedAt }/>
                <Stats title={ t( 'device.info.last_connected_label' ) } content={ formattedLastSeen }/>
                <Stats title={ t( 'device.info.metrics_update_label' ) } content={ formattedMetricsTimestamp }/>
                <Stats title={ t( 'device.info.uid' ) } content={ deviceInfo?.deviceUuid }/>
                <Stats title={ t( 'device.info.firmware_version_label' ) }
                    content={ deviceInfo?.currentVersion ?? 'Unknown' }/>
                <Stats title={ t( 'device.info.current_ip_label' ) } content={ deviceInfo?.currentLocalIp }/>
                <Stats title={ t( 'device.info.uptime_label' ) } content={ formattedUptime }/>
            </Grid>
        </Card>
    );
}