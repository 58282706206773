import { Grid } from '@chakra-ui/react';
import { GameModelData } from "@mondstein/api";
import React from 'react';
import GameCard from '../GameCard/GameCard';

export type GameGridProps = {
    /** List of games to show in the grid */
    games: GameModelData[];

    /**
     * Number of columns per row
     */
    cols?: number;
}

/**
 * Grid showing multiple games
 * @constructor
 */
export default function GameGrid({ games, cols }: GameGridProps) {
    const templateColumns = `repeat(${ cols || 4 }, 1fr)`;

    return (<Grid templateColumns={ templateColumns } gap={ 12 }>
        { games.map( (game) => <GameCard key={ game.id }
                                         id={ game.id }
                                         visualUrl={ game.primaryVisual }
                                         is_featured={ game.is_featured }
                                         name={ game.name }
                                         description={ game.description } price={ game.price }
                                         currency={ game.currency }/> ) }
    </Grid>);
}
