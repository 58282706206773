import { Box, Collapse, Flex, Heading, IconButton, useDisclosure } from '@chakra-ui/react';
import React, { PropsWithChildren, useMemo } from 'react';
import { ChevronsDown, ChevronsUp } from 'react-feather';

export type SectionProps = PropsWithChildren<{
    /** Title of the current section */
    title?: string;

    /** Flag if the section can be collapsed */
    isCollapsible?: boolean;

    /** Render the component always in the open state */
    isForceOpen?: boolean;
}>;

export const Section = ({ title, children, isCollapsible, isForceOpen }: SectionProps) => {
    const { isOpen, onToggle } = useDisclosure();

    const showContent = useMemo( () => {
        if (isForceOpen) {
            return true;
        }

        if (isCollapsible) {
            return isOpen;
        }
        return true;
    }, [isCollapsible, isOpen] )


    const renderHeading = () => {
        if (!title) {
            return null;
        }

        return <Flex justifyContent={ 'space-between' }>
            <Heading as="h3" size="md">{ title }</Heading>
            { isCollapsible && <IconButton
                variant={ 'outline' } onClick={ onToggle } aria-label={ 'Toggle content' }
                icon={ showContent ? <ChevronsUp/> : <ChevronsDown/> }/>
            }
        </Flex>
    }

    return <Box p={ 4 } borderWidth="1px">
        { renderHeading() }

        <Collapse in={ showContent } animateOpacity>
            <Box mt={ 6 }>
                { children }
            </Box>
        </Collapse>
    </Box>
}
