import { GameModelData } from "@mondstein/api";
import { useGameApi } from "@mondstein/hooks";
import { useEffect, useState } from 'react';

// @todo export loader function
export const useGameData = (gameId: number | null) => {
    const [gameData, setGameData] = useState<GameModelData | null>( null );
    const [isLoading, setIsLoading] = useState( false );
    const { load } = useGameApi();

    useEffect( () => {
        if (!gameId) {
            return;
        }

        setIsLoading( true );
        load( gameId )
            .then( (gameModelData) => {
                setGameData( gameModelData );
            } )
            .finally( () => {
                setIsLoading( false );
            } )
    }, [gameId] );

    return { isLoading, gameData };
}
