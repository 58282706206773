import { PurchaseModelData } from "@mondstein/api";
import { useCheckoutApi } from "@mondstein/hooks";
import { useEffect, useState } from 'react';

export const usePurchase = (purchaseId: number | null): [PurchaseModelData | null, boolean] => {
    const [isLoading, setIsLoading] = useState( false );
    const [purchase, setPurchase] = useState<PurchaseModelData | null>( null );
    const { getPurchase } = useCheckoutApi();

    useEffect( () => {
        if(!purchaseId){
            return;
        }

        setIsLoading( true );
        getPurchase( purchaseId )
            .then( (purchase) => {
                setPurchase( purchase );
            } )
            .finally( () => {
                setIsLoading( false );
            } )
    }, [purchaseId] );

    return [purchase, isLoading];
}
