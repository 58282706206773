import { Grid } from "@chakra-ui/react";
import { DeviceModelData } from "@mondstein/api";
import { useDeviceApi } from "@mondstein/hooks";
import { BarChart, Box, Card, SimpleHistogram } from "@mondstein/ui";
import React, { useCallback, useEffect, useState } from 'react';
import { BarChart2, TrendingDown } from "react-feather";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useInterval } from "react-use";
import PageHeading from "../../../modules/base/components/PageHeading/PageHeading";
import { useContentSize } from "../../../modules/base/hooks/use-content-size";
import { useSeries } from "../../../modules/base/hooks/use-series";
import { useBreadcrumbs } from "../../../modules/base/hooks/useBreadcrumbs";
import { ContentSizes } from "../../../modules/base/types/ContentSizes";
import DeviceInfo from "../../../modules/device/components/DeviceInfo/DeviceInfo";

export type DeviceDetailPageProps = {}
export type DeviceDetailPageParams = {
    uuid: string;
}

export default function DeviceDetailPage({}: DeviceDetailPageProps) {
    const [t] = useTranslation();
    const [deviceInfo, setDeviceInfo] = useState<DeviceModelData | null>( null );
    const [isLoading, setIsLoading] = useState( false );
    const { uuid } = useParams<DeviceDetailPageParams>();
    const { loadDeviceInfo } = useDeviceApi();
    const [loadSerie, addLoadValue] = useSeries( [] );
    const [ramSerie, addRamValue] = useSeries( [] );
    const [batterieSerie, addBatteryValue] = useSeries( [] );
    const [batteryValue, setBatteryValue] = useState( 0 );

    useContentSize( ContentSizes.FULL )

    useBreadcrumbs( [
        { text: t( 'nav.profile.title' ), url: '/users/me' },
        { text: t( 'nav.devices.title' ), url: '/users/me/devices' },
        { text: deviceInfo?.deviceUuid || '', url: `/users/me/devices${ deviceInfo?.deviceUuid }` },
    ], [deviceInfo] );

    const fetchDevice = useCallback( () => {
        if(!uuid){
            return;
        }

        setIsLoading( true );
        loadDeviceInfo( uuid )
            .then( (data) => {
                setDeviceInfo( data );
                addLoadValue( data.load1m );
                addRamValue( data.ramUsage );
                addBatteryValue( data.battery );
                setBatteryValue( data.battery );
            } )
            .finally( () => {
                setIsLoading( false );
            } )
    }, [addLoadValue, uuid] );

    useInterval( () => {
        fetchDevice();
    }, 2000 );

    useEffect( () => {
        fetchDevice();
    }, [uuid] );

    return (<div className="DeviceDetailPage">
        <PageHeading title={ deviceInfo?.name || '' } backLink={ '/users/me/devices' }/>
        { deviceInfo && <DeviceInfo deviceInfo={ deviceInfo }/> }
        <Box mt={ 4 }>
            <PageHeading title={ 'Metrics' }/>
            <Grid mt={ 4 } templateAreas={ 'left right' } templateColumns={ '1fr 1fr' } gap={ 4 }>
                <Card title={ 'Load' } actions={ <TrendingDown/> }>
                    { deviceInfo && <SimpleHistogram values={ loadSerie }/> }
                </Card>

                <Card title={ 'RAM' } actions={ <TrendingDown/> }>
                    { deviceInfo && <SimpleHistogram values={ ramSerie }/> }
                </Card>

                <Card title={ 'Battery' } actions={ <TrendingDown/> }>
                    { deviceInfo && <SimpleHistogram values={ batterieSerie }/> }
                </Card>

                <Card title={ 'Battery' } actions={ <BarChart2/> }>
                    { deviceInfo && <BarChart data={ [{ name: 'Battery', value: batteryValue }] }/> }
                </Card>
            </Grid>
        </Box>
    </div>);
}
