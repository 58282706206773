import { Box, Flex, useToast } from '@chakra-ui/react';
import { useAsyncData, useBackendInfoApi } from "@mondstein/hooks";
import { Stats } from "@mondstein/ui";
import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "react-use";
import { version } from '../../../../package.json';
import { FullScreenLoader } from "./FullScreenLoader";

const FE_ENV = process.env.REACT_APP_ENVIRONMENT;

export const FooterBar = () => {
    const [feVersion, setFeVersion] = useLocalStorage( 'fe_version' );
    const toast = useToast();
    const [t] = useTranslation();
    const { loadInfo } = useBackendInfoApi();
    const [isLoading, backendInfo] = useAsyncData( () => loadInfo() );

    useEffect( () => {
        if (feVersion !== version) {
            toast( {
                title: t( 'general.new_fe_version.title' ),
                description: t( 'general.new_fe_version.description', { version } ),
                status: "info"
            } );
        }
        setFeVersion( version );
    }, [] );

    if (isLoading) {
        return <FullScreenLoader/>
    }

    return <Flex width="100%" height="120px" justifyContent="center" mt={ 10 } p={ 4 }>
        <Flex maxWidth={ 1440 } width="100%" justifyContent="space-between">
            <Box>
                <Stats title={ 'Frontend Env' } content={ <p>{ FE_ENV }</p> }/>
                <Stats title={ 'Frontend Version' } content={ <p>v{ version }</p> }/>
            </Box>

            <Box>
                <Stats title={ 'Backend' } content={ <p>{ process.env.REACT_APP_BACKEND }</p> }/>
                <Stats title={ 'Backend Env' } content={ <p>{ backendInfo?.environment }</p> }/>
                <Stats title={ 'Backend Version' } content={ <p>v{ backendInfo?.version }</p> }/>
            </Box>
        </Flex>
    </Flex>
}

export default FooterBar;