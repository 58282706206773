import * as React from 'react';
import { CustomRoute } from "../../router/routes";
import GameCreatePage from "./GameCreatePage/GameCreatePage";
import GameEditPage from "./GameEditPage/GameEditPage";
import GamesListPage from "./GamesListPage/GamesListPage";
import Main from "./Main/Main";
import PublisherCreatePage from "./PublisherCreatePage/PublisherCreatePage";
import PublisherEditPage from "./PublisherEditPage/PublisherEditPage";
import PublisherListPage from "./PublisherListPage/PublisherListPage";

export const studioRoutes: CustomRoute[] = [
    {
        path: '/studio',
        element: <Main/>,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '/studio/publisher/create',
                element: <PublisherCreatePage/>
            },
            {
                path: '/studio/publisher/:id',
                element: <PublisherEditPage/>
            },
            {
                path: '/studio/publisher',
                element: <PublisherListPage/>
            },
            {
                path: '/studio/games',
                element: <GamesListPage/>
            },
            {
                path: '/studio/games/new',
                element: <GameCreatePage/>
            },
            {
                path: '/studio/games/:id',
                element: <GameEditPage/>
            },
        ]
    },
]

