import { useToast } from "@chakra-ui/react";
import { CreateGameData, useGameApi, usePublisherApi } from "@mondstein/hooks";
import React, { useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { FullScreenLoader } from "../../../modules/base/components/FullScreenLoader";
import PageHeading from "../../../modules/base/components/PageHeading/PageHeading";
import { useAsyncData } from "../../../modules/base/hooks/use-async-data";
import { useBreadcrumbs } from "../../../modules/base/hooks/useBreadcrumbs";
import GameEditForm from "../../../modules/studio/components/GameEditForm/GameEditForm";

export type GameCreatePageProps = {}

export default function GameCreatePage({}: GameCreatePageProps) {
    const [t] = useTranslation();
    const toast = useToast();
    const { createGame } = useGameApi();
    const { fetchList } = usePublisherApi();
    const [isLoading, publishers] = useAsyncData( () => fetchList() );

    useBreadcrumbs( [
        { text: t( 'general.studio' ), url: '/studio' },
        { text: t( 'nav.games_list.title' ), url: '/studio/games' },
        { text: t( 'nav.new_game.title' ), url: '/studio/games/new' },
    ] );

    const onSubmit = useCallback( (data: CreateGameData) => {
        createGame( data )
            .then( (game) => {
                toast( {
                    title: t( `general.game_created.title` ),
                    description: t( `general.game_created.description`, { name: game.name } ),
                    status: 'success'
                } );
            } );
    }, [] );

    if (isLoading || !publishers) {
        return <FullScreenLoader/>
    }

    return (<div className="GameCreatePage">
        <PageHeading backLink={ '/studio/games' } title={ t( 'general.new_game.title' ) }/>
        <GameEditForm onSubmit={ onSubmit } listOfPublishers={ publishers }/>
    </div>);
}
