import { Box, Divider, Flex, Icon, Text, VStack } from "@chakra-ui/react";
import { PRIMARY_HUE } from "@mondstein/ui";
import React, { ReactNode } from 'react';
import { ChevronRight } from "react-feather";
import { Link } from "react-router-dom";

export type SimpleListItem = {
    id: number | string;
    label: string;
    to?: string;
    active?: boolean;
    secondary?: ReactNode;
};

export type SimpleListProps = {
    list?: SimpleListItem[];
    children?: ReactNode;
}

export default function SimpleList({ list }: SimpleListProps) {
    const renderItemContent = (item: SimpleListItem) => (<>
        <Box>
        </Box>
        <Text variant={ 'p' } color={ item.active ? PRIMARY_HUE : 'inherit' }>
            { item.label }
        </Text>
    </>);

    const renderLinkedItem = (item: SimpleListItem) => (<>
        <Flex as={ Link } key={ item.id }
            /* @ts-ignore */
            to={ item.to }
            justifyContent={ 'space-between' }
            alignItems={ 'center' }
            pl={ 4 } pr={ 4 } pb={ 2 } pt={ 2 }
            w={ '100%' }>
            <Box>
                { renderItemContent( item ) }
            </Box>
            <Box display={ 'flex' } alignItems={ 'center' }>
                { item.secondary }
                <Icon color={ PRIMARY_HUE } ml={ 1 }>
                    <ChevronRight/>
                </Icon>
            </Box>
        </Flex>
        <Divider key={ `divider_${ item.id }` }/>
    </>);

    const renderItem = (item: SimpleListItem) => (<>
        <Flex key={ item.id }>
            { renderItemContent( item ) }
        </Flex>
        <Divider/>
    </>);

    return (<VStack>
        { list?.map( item => {
            if (!!item.to) {
                return renderLinkedItem( item );
            }
            return renderItem( item );
        } ) }
    </VStack>);
}
