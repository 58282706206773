import { ACCESS_CLAIM } from "@mondstein/api";
import { PathRouteProps } from 'react-router';
import { adminRoutes } from '../pages/admin/routes';
import { authRoutes } from '../pages/auth/routes';
import { checkoutRoutes } from '../pages/checkout/routes';
import { dashboardRoutes } from '../pages/dashboard/routes';
import { deviceRoutes } from '../pages/device/routes';
import { gamesRoutes } from '../pages/games/routes';
import { studioRoutes } from '../pages/studio/routes';
import { userRoutes } from '../pages/user/routes';

export type CustomRoute = PathRouteProps & {
    meta?: {
        requiresAuth: boolean;
        claims?: ACCESS_CLAIM[];
    };
    children?: CustomRoute[];
}

export const routes: CustomRoute[] = [
    ...authRoutes,
    ...studioRoutes,
    ...dashboardRoutes,
    ...gamesRoutes,
    ...deviceRoutes,
    ...userRoutes,
    ...checkoutRoutes,
    ...adminRoutes,
];
