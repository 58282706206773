import { Box, Button, Flex, Image } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PasswordRepeat } from '../../../user/components/PasswordRepeat/PasswordRepeat';

export type ReetFormProps = {
    /** Handler when save btn is clicked */
    onSave: (pw: string) => void;

    /** Flag if the request is pending */
    isLoading: boolean;
}

export const ResetForm = ({ isLoading, onSave }: ReetFormProps) => {
    const [newPassword, setNewPassword] = useState( '' );
    const [isPwValid, setPwValid] = useState( false );
    const [t] = useTranslation();

    return (<Box>
        <Flex justifyContent="center">
            <Image
                boxSize="100px"
                objectFit="cover"
                src="/logo_white.svg"
                alt="Mondstein Logo"
            />
        </Flex>

        <PasswordRepeat onChange={ setNewPassword } onIsValidChanged={ setPwValid }/>

        <Flex justifyContent="flex-end" mt={ 4 }>
            <Button  isDisabled={ !isPwValid }
                    isLoading={ isLoading }
                    onClick={ () => onSave( newPassword ) }>
                { t( 'general.save' ) }
            </Button>
        </Flex>
    </Box>);
}

export default ResetForm;
