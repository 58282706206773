import { Flex } from '@chakra-ui/react';
import { useAuthenticationApi, useToast } from '@mondstein/hooks';
import { Box } from '@mondstein/ui';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import LoginForm from '../../modules/auth/components/LoginForm/LoginForm';
import { isAuthenticatedState } from '../../modules/auth/state/is-authenticated';
import { FullScreenLoader } from "../../modules/base/components/FullScreenLoader";
import { useIsAuthenticated } from "../../modules/base/hooks/use-is-authenticated";

export function LoginPage() {
    const [isLoading, setLoading] = useState( false );
    const toast = useToast();
    const [, setIsAuthenticated] = useRecoilState( isAuthenticatedState );
    const [t] = useTranslation();
    const { doLogin } = useAuthenticationApi();
    const [isLoadingAuthState, isAuthenticated] = useIsAuthenticated();

    const onLogin = useCallback( (username: string, password: string) => {
        setLoading( true )

        doLogin( username, password )
            .then( (success) => {
                setIsAuthenticated( success );
                toast.success( t( 'login.success.title' ) );
            } )
            .catch( () => {
                toast.error( t( 'login.failed.title' ) );
            } )
            .finally( () => {
                setLoading( false )
            } );
    }, [] );

    if (isLoadingAuthState) {
        return <FullScreenLoader/>;
    }

    if (isAuthenticated) {
        return <Navigate to={ '/' } replace/>;
    }

    return (<Flex w="100vw" h="100vh" align="center" justifyContent="center">
        <Box maxW={ 600 } w={ '100%' } p={ 10 }>
            <LoginForm onLogin={ onLogin } isLoading={ isLoading }/>
        </Box>
    </Flex>);
}

export default LoginPage;
