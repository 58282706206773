import { useToast } from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";

export const useAsyncData = <T>(loader: () => Promise<T | void> | null, deps: any[] = [], onError?: (error: AxiosError) => void): [boolean, T | null, () => void, AxiosError | null] => {
    const [isLoading, setIsLoading] = useState( false );
    const [error, setError] = useState<AxiosError | null>( null );
    const [loadedData, setLoadedData] = useState<T | null>( null );

    const fetch = useCallback( () => {
        const promise = loader();
        if (!promise) {
            return;
        }

        setIsLoading( true );
        promise
            .then( (data) => {
                data && setLoadedData( data );
                return data;
            } )
            .catch( (err: AxiosError) => {
                setError( err );
                if (onError) {
                    return onError?.( err );
                }
                throw err;
            } )
            .finally( () => {
                setIsLoading( false );
            } )
    }, [...deps] );

    const refresh = useCallback( () => {
        fetch();
    }, [fetch] );

    useEffect( () => {
        fetch()
    }, [fetch] );

    return [isLoading, loadedData, refresh, error];
}

export const useAsyncLoader = <T, PARAMS = unknown>(loader: (params: PARAMS) => Promise<T | void | null> | null, deps: any[] = []): [boolean, (params: PARAMS) => void, T | null] => {
    const [isLoading, setIsLoading] = useState( false );
    const [loadedData, setLoadedData] = useState<T | null>( null );
    const toast = useToast();

    const fetch = useCallback( (p: PARAMS) => {
        const promise = loader( p );
        if (!promise) {
            return;
        }

        setIsLoading( true );
        promise
            .then( (data) => {
                data && setLoadedData( data );
                return data;
            } )
            .catch( (err: AxiosError) => {
                if (err.isAxiosError && err.response?.status === 500) {
                    toast( {
                        title: 'Unknown error',
                        description: err.response.data.message,
                        status: "error",
                    } );
                }
                throw err;
            } )
            .finally( () => {
                setIsLoading( false );
            } )
    }, [...deps] );

    return [isLoading, fetch, loadedData];
}