import { useMemo } from 'react';
import { CURRENT_LOCALE } from '../../../config/locale';

/**
 * Hook to format a date object for the current locale
 */
export const useFormattedDate = (date: Date | undefined | string, style: "medium" | "full" | "long" | "short" | undefined = 'medium') => {
    return useMemo( () => {
        if (!date) {
            return null;
        }
        const parsedDate = typeof date === 'string' ? new Date( date ) : date;
        return new Intl.DateTimeFormat( CURRENT_LOCALE, { dateStyle: style } ).format( parsedDate )
    }, [date] );
}
