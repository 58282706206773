import { Grid, VStack } from '@chakra-ui/react';
import { useUserApi } from "@mondstein/hooks";
import { PrimaryBtn } from "@mondstein/ui";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FullScreenLoader } from "../../../modules/base/components/FullScreenLoader";
import { useAsyncData } from "../../../modules/base/hooks/use-async-data";
import { useBreadcrumbs } from "../../../modules/base/hooks/useBreadcrumbs";
import { UserProfileCard } from '../../../modules/user/components/UserProfileCard/UserProfileCard';

/**
 * Page component for the users profile page
 * @constructor
 */
export default function UserProfilePage() {
    const [t] = useTranslation();
    const { getUser } = useUserApi();
    const [isLoading, userInfo] = useAsyncData( () => getUser() );

    useBreadcrumbs( [
        { text: t( 'general.store' ), url: '/' },
        { text: t( 'nav.profile.title' ), url: '/users/me' },
    ] );

    if (isLoading) {
        return <FullScreenLoader/>
    }

    return (
        <Grid templateColumns="8fr 4fr" gap={ 8 }>
            <VStack spacing={ 6 }
                align="stretch">
            </VStack>

            <VStack spacing={ 6 }
                align="stretch">
                { userInfo && <UserProfileCard userInfo={ userInfo }/> }
                <PrimaryBtn as={ Link } to={ '/users/me/change-password' }>Change
                    Password</PrimaryBtn>

                <PrimaryBtn as={ Link } to={ '/users/me/devices' }>
                    { t( userInfo?.hasConnectedDevice ? `general.devices` : `general.connect_new_device` ) }
                </PrimaryBtn>
                
                <PrimaryBtn as={ Link } to={ '/users/me/settings' }>
                    { t( `general.settings` ) }
                </PrimaryBtn>
            </VStack>
        </Grid>
    );
}