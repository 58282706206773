import React from 'react';
import { Outlet } from "react-router-dom";
import Container from '../../../modules/base/components/Container/Container';
import AppLayout from '../../../modules/base/layouts/AppLayout';
import { ContentSizes } from '../../../modules/base/types/ContentSizes';

export type MainProps = {}

export default function Main({}: MainProps) {
    return (<AppLayout>
        <Container size={ ContentSizes.NARROW } showBreadcumbs={ true }>
            <Outlet/>
        </Container>
    </AppLayout>);
}
