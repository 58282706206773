import * as React from 'react';
import { CustomRoute } from '../../router/routes';
import ChangePasswordPage from "./ChangePasswordPage/ChangePasswordPage";
import Main from './Main/Main';
import UserProfilePage from "./UserProfilePage/UserProfilePage";
import { UserSettingsPage } from "./UserSettingsPage/UserSettingsPage";

export const userRoutes: CustomRoute[] = [
    {
        path: '/users/me',
        element: <Main/>,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: 'change-password',
                element: <ChangePasswordPage/>,
            },
            {
                path: 'settings',
                element: <UserSettingsPage/>,
            },
            {
                path: '',
                element: <UserProfilePage/>,
            },
        ]
    },
];
