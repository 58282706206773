import { FormControl, FormLabel, Input, Select, VStack } from "@chakra-ui/react";
import { getDeviceOwner, getListOfUsers, updateDeviceName, updateDeviceOwner, UserModelData } from "@mondstein/api";
import { useDeviceApi, useErrorHandler, useToast } from "@mondstein/hooks";
import { BackButton, Card, } from "@mondstein/ui";
import * as React from "react";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useAsyncData, useAsyncLoader } from "../../../modules/base/hooks/use-async-data";
import { useBreadcrumbs } from "../../../modules/base/hooks/useBreadcrumbs";

export type DeviceEditPageParams = {
    id: string;
}

export const DeviceEditPage = () => {
    const [t] = useTranslation();
    const { id } = useParams<DeviceEditPageParams>();
    const { getDeviceInfo } = useDeviceApi();
    const toast = useToast();
    const errorHandler = useErrorHandler();
    const [isLoading, deviceInfo, refreshDeviceInfo] = useAsyncData( () => {
        if (!id) {
            return null;
        }
        return getDeviceInfo( id )
    }, [id] );
    const [isUsersLoading, usersList] = useAsyncData( () => getListOfUsers() );
    const [isSaving, setIsSaving] = useState( false );
    const [newOwnerId, setNewOwnerId] = useState<number | null>( null );
    const [deviceName, setDeviceName] = useState<string | null>( null );
    const [isLoadingOwner, deviceOwnerLoader, deviceOwner] = useAsyncLoader<UserModelData, { deviceUUID?: string }>( ({ deviceUUID }) => {
        if (!deviceUUID) {
            return null;
        }
        return getDeviceOwner( deviceUUID )
    }, [deviceInfo] );

    useEffect( () => {
        if (!deviceInfo) {
            return;
        }

        setDeviceName( deviceInfo.name );
        deviceOwnerLoader( { deviceUUID: deviceInfo.deviceUuid } );
    }, [deviceInfo] );


    useBreadcrumbs( [
        { text: t( 'general.admin' ), url: '/admin' },
        { text: t( 'admin.devices.list' ), url: '/admin/devices' },
        { text: deviceInfo?.name ?? '', url: `/admin/devices/${ deviceInfo?.id ?? '' }` },
    ], [deviceInfo] );

    const onOwnerChanged = (event: ChangeEvent<HTMLSelectElement>) => {
        if (!deviceInfo) {
            return;
        }

        const newId = parseInt( event.target.value );
        setIsSaving( true );
        updateDeviceOwner( deviceInfo.deviceUuid, newId )
            .then( () => {
                setNewOwnerId( newId )
                toast.success( t( 'device.owner_change_success' ) );
                return refreshDeviceInfo();
            } )
            .catch( errorHandler )
            .finally( () => {
                setIsSaving( false )
            } );
    }

    const saveDeviceName = () => {
        if (!deviceInfo || !deviceName) {
            return;
        }
        setIsSaving( true )
        updateDeviceName( deviceInfo.id, deviceName )
            .then( () => {
                setDeviceName( deviceName );
                toast.success( t( 'device.name_change_success' ) );
                return refreshDeviceInfo();
            } )
            .catch( errorHandler )
            .finally( () => {
                setIsSaving( false );
            } )
    }

    const selectedUserValue = useMemo( () => {
        if (!newOwnerId && !deviceOwner) {
            return null;
        }
        return newOwnerId ? newOwnerId : deviceOwner?.id
    }, [newOwnerId, deviceOwner, usersList] );

    return <Card title={ `#${ deviceInfo?.id } - ${ deviceInfo?.name }` }
        avatar={ <Link to={ `/admin/devices/${ deviceInfo?.id }` }><BackButton/></Link> }>
        <VStack spacing={ 4 } alignItems={ 'flex-start' }>

            <FormControl>
                <FormLabel>{ t( 'general.name' ) }</FormLabel>
                <Input value={ deviceName ?? '' }
                    disabled={ isLoading || isSaving }
                    placeholder={ 'Name' }
                    onChange={ (e) => setDeviceName( e.target.value ) }
                    onBlur={ saveDeviceName }/>
            </FormControl>

            <FormControl>
                <FormLabel>{ t( 'general.owner' ) }</FormLabel>
                <Select placeholder='Select user'
                    disabled={ isLoading || isSaving }
                    value={ selectedUserValue ?? undefined }
                    onChange={ onOwnerChanged }>
                    { usersList?.map( (user) => (
                        <option
                            value={ user.id } key={ user.id }>{ user.username } - ({ user.email })</option>
                    ) ) }
                </Select>
            </FormControl>
        </VStack>
    </Card>

}