import { Button, FormControl, Input, Tab, TabList, TabPanel, TabPanels, Tabs, VStack, } from "@chakra-ui/react";
import { GameModelData, getGameBinaryUrl } from "@mondstein/api";
import { useGameApi, useNotFoundHandler, usePublisherApi, useToast } from "@mondstein/hooks";
import {
    BackButton,
    Card,
    LoadingIndicator,
    PRIMARY_COLOR,
    Stats,
    StatusBadge,
    TertiaryBtn,
    TextField,
} from "@mondstein/ui";
import React, { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import Error404 from "../../../modules/base/components/Error404/Error404";
import { FullScreenLoader } from "../../../modules/base/components/FullScreenLoader";
import { useAsyncData } from "../../../modules/base/hooks/use-async-data";
import { useBreadcrumbs } from "../../../modules/base/hooks/useBreadcrumbs";
import GameEditForm from "../../../modules/studio/components/GameEditForm/GameEditForm";

export type GameEditPageProps = {}
export type GameEditPageParams = {
    id: string;
}

export default function GameEditPage({}: GameEditPageProps) {
    const [t] = useTranslation();
    const toast = useToast();
    const { id } = useParams<GameEditPageParams>();
    const { load, updateGame, loadBinaryStats, uploadGame } = useGameApi();
    const [isNotFound, handleError] = useNotFoundHandler();
    const { fetchList } = usePublisherApi();
    const [isLoading, game] = useAsyncData<GameModelData>( () => {
        if (!id) {
            return null
        }

        return load( parseInt( id ) ).catch( handleError )
    }, [id] );
    const [isPublisherLoading, publishers] = useAsyncData( () => fetchList() );
    const [isBinaryStatsLoading, binaryStats] = useAsyncData( () => {
        if (!id) {
            return null;
        }
        return loadBinaryStats( parseInt( id ) )
    }, [id] );
    const [gameIsUploading, setGameIsUploading] = useState( false );

    useBreadcrumbs( [
        { text: t( 'general.studio' ), url: '/studio' },
        { text: t( 'nav.games_list.title' ), url: '/studio/games' },
        { text: game?.name ?? '', url: `/studio/games/${ game?.id }` },
    ], [game] );

    const onSubmit = useCallback( (gameData) => {
        if (!id) {
            return;
        }

        updateGame( parseInt( id ), gameData )
            .then( () => {
                toast.success( t( 'general.game_update_successfully.title' ) );
            } );
    }, [] );

    const onFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        if (!game) {
            return;
        }

        const loader = uploadGame( game, e.target.files?.[0] );
        setGameIsUploading( true );

        // @ts-ignore
        toast.promise( loader, 'Loading', t( 'general.upload.successful' ), t( 'general.upload.failed' ) )
            // @ts-ignore
            .finally( () => {
                setGameIsUploading( false );
            } );
    }

    if (isNotFound) {
        return <Error404 cta={ <Button as={ Link } to={ '/studio/games' }>{ t( 'general.back_to_studio' ) }</Button> }/>
    }

    if (isLoading || isPublisherLoading) {
        return <FullScreenLoader/>
    }

    return (
        <>
            <Tabs variant="soft-rounded" colorScheme={ PRIMARY_COLOR }>
                <TabList>
                    <Tab>{ t( 'game.general_view' ) }</Tab>
                    <Tab>{ t( 'game.files' ) }</Tab>
                    <Tab>{ t( 'general.stats' ) }</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <GameEditForm
                            cardProps={ {
                                title: game?.name,
                                avatar: <Link to={ `/studio/games` }><BackButton/></Link>,
                                actions: <Link to={ `/store/games/${ id }` }>
                                    <TertiaryBtn>
                                        { t( 'general.preview' ) }
                                    </TertiaryBtn>
                                </Link>,
                            } }
                            game={ game ?? null }
                            onSubmit={ onSubmit }
                            listOfPublishers={ publishers ?? [] }/>
                    </TabPanel>

                    <TabPanel>
                        <Card title={ t( 'game.files' ) }
                            actions={ isBinaryStatsLoading ? <LoadingIndicator/> : <StatusBadge
                                status={ (binaryStats?.exists ? 'SUCCESS' : 'ERROR') }>{ t( binaryStats?.exists ? 'game.file_exists' : 'game.file_not_found' ) }</StatusBadge>
                            }>

                            <VStack align={ 'flex-start' } spacing={ 2 }>
                                <Stats title={ 'Size' } content={ `${ binaryStats?.size ?? 'K/N' }` }/>
                                <Stats title={ 'Binary Url' }
                                    content={ <a target={ '_blank' }
                                        rel="noreferrer"
                                        href={ `${ process.env.REACT_APP_BACKEND }${ game && getGameBinaryUrl( game ) }` }>{ `${ process.env.REACT_APP_BACKEND }${ game && getGameBinaryUrl( game ) }` }</a> }/>

                                <FormControl>
                                    <TextField label={ t( 'game.binary_url' ) }
                                        value={ game?.binaryUrl }
                                        disabled
                                        placeholder={ '' }/>
                                </FormControl>

                                <FormControl>
                                    <Input label={ t( 'game.binary_url' ) }
                                        type={ 'file' }
                                        disabled={ gameIsUploading }
                                        onChange={ onFileUpload }
                                        placeholder={ t( 'game.binary_url.placeholder' ) }/>
                                </FormControl>
                            </VStack>

                        </Card>
                    </TabPanel>
                </TabPanels>
            </Tabs>


        </>);
}
