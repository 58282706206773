import { useGameApi } from "@mondstein/hooks";
import { useEffect, useState } from 'react';

// @todo export loader function
export const useIsGamePurchased = (gameId: number | null) => {
    const [isLoading, setIsLoading] = useState( false );
    const [isPurchased, setIsPurchased] = useState( false );
    const api = useGameApi();

    useEffect( () => {
        if (!gameId) {
            return;
        }

        setIsLoading( true );
        api.isPurchased( gameId )
            .then( (purchased) => {
                setIsPurchased( purchased );
            } )
            .finally( () => {
                setIsLoading( false );
            } );
    }, [gameId] );

    return [isPurchased, isLoading];
}
