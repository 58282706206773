import { Divider, Skeleton, Stack } from "@chakra-ui/react";
import React from 'react';

export type SimpleListSkeletonProps = {
    items?: number;
}

export default function SimpleListSkeleton({ items }: SimpleListSkeletonProps) {
    return <Stack>
        { Array.from( Array( items ?? 3 ) ).map( (item, key) => {
            return (<>
                <Skeleton key={ `skel_${ key }` } height="32px"/>
                <Divider key={ `div_${ key }` }/>
            </>);
        } ) }
    </Stack>;
}
