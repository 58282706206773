import { useAuthenticationApi } from "@mondstein/hooks";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { COOKIE_NAME } from "../config/constances";
import { isAuthenticatedState } from "../modules/auth/state/is-authenticated";
import { isCookieVerifiedState } from "../modules/auth/state/is-cookie-verified-state";

export const useAuth = () => {
    const [isAuthenticated, setAuth] = useRecoilState( isAuthenticatedState );
    const [isCookieVerified, setCookieVerified] = useRecoilState( isCookieVerifiedState );
    const [isLoading, setLoading] = useState( false );
    const { checkAuth } = useAuthenticationApi();

    const authCheck = () => {
        setLoading( true );
        checkAuth()
            .then( (isValid) => {
                setAuth( isValid );
                Cookies.set( COOKIE_NAME, '' );
            } )
            .catch( () => {
                setAuth( false );
            } )
            .finally( () => {
                setCookieVerified( true );
                setLoading( false );
            } )
    };

    useEffect( () => {
        if (!isAuthenticated || !isCookieVerified) {
            authCheck();
        }
    }, [] );

    return {isLoading, isAuthenticated}
}