import { PublisherModelData } from "@mondstein/api";
import { usePublisherApi } from "@mondstein/hooks";
import { useEffect, useState } from "react";

export const usePublisherList = () => {
    const [isLoading, setIsLoading] = useState( true );
    const [list, setList] = useState<PublisherModelData[]>( [] );
    const { fetchList } = usePublisherApi();

    useEffect( () => {
        setIsLoading( true );
        fetchList()
            .then( (publishers) => {
                setList( publishers );
            } )
            .finally( () => {
                setIsLoading( false );
            } )
    }, [] );

    return { isLoading, list };
}