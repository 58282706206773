import React from "react";
import { Route, Routes } from "react-router-dom";
import { PrivateRoute } from "../modules/base/components/PrivateRoute";
import NotFoundPage from "../modules/base/pages/NotFoundPage";
import { CustomRoute } from "../router/routes";

export type RouteMapProps = {
    routes: CustomRoute[];
};

export const RouteMap = ({ routes }: RouteMapProps) => {

    const renderRoutes = (currentRoutes: CustomRoute[]) => {
        return <>
            { currentRoutes.map( (route, i) => {
                if (route.meta?.requiresAuth) {
                    return <Route key={ i } path={ route.path }
                        element={ <PrivateRoute element={ route.element }/> }>
                        { route.children && renderRoutes( route.children ?? [] ) }
                    </Route>
                }
                return <Route path={ route.path }
                    element={ route.element }
                    key={ i }>
                    { route.children && renderRoutes( route.children ?? [] ) }
                </Route>;
            } ) }
        </>
    }
    return <Routes>
        { renderRoutes( routes ) }
        <Route element={ NotFoundPage }/>
    </Routes>
}