import { Flex, FormControl, FormLabel, Input, useToast } from "@chakra-ui/react";
import { useDeviceApi } from "@mondstein/hooks";
import { PrimaryBtn } from "@mondstein/ui";
import * as React from "react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Section } from "../../../modules/base/components/Section";

export const RegisterDevicePage = () => {
    const [deviceId, setDeviceId] = useState( '' );
    const [isLoading, setLoading] = useState( false );
    const toast = useToast();
    const [t] = useTranslation();
    const { connectToUser } = useDeviceApi();

    const onConnect = useCallback( () => {
        setLoading( true );
        connectToUser( deviceId )
            .then( () => {
                toast( {
                    title: t( 'device.linked.success.title' ),
                    description: t( 'device.linked.success.description' ),
                    status: 'success',
                } );
            } )
            .catch( () => {
                toast( {
                    title: t( 'device.linked.failed.title' ),
                    description: t( 'device.linked.failed.description' ),
                    status: 'error',
                } );
            } )
            .finally( () => {
                setLoading( false );
            } );
    }, [deviceId] );

    return (
        <Section title={ t( 'device.link.title' ) }>
            <FormControl>
                <FormLabel>{ t( 'device.link.device' ) }</FormLabel>
                <Input value={ deviceId }
                    placeholder={ 'Device UUID' }
                    onChange={ (e) => setDeviceId( e.target.value ) }
                    size="lg"/>
            </FormControl>

            <Flex justifyContent={ 'flex-end' } mt={ 6 }>
                <PrimaryBtn disabled={ !deviceId } onClick={ onConnect } isLoading={ isLoading }>
                    { t( 'device.link.cta' ) }
                </PrimaryBtn>
            </Flex>
        </Section>
    );
}