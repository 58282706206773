import { DeviceModelData } from "@mondstein/api";
import { useDeviceApi } from "@mondstein/hooks";
import { BackButton, Card, TertiaryBtn } from "@mondstein/ui";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FullScreenLoader } from "../../../modules/base/components/FullScreenLoader";
import SimpleList from "../../../modules/base/components/SimpleList/SimpleList";
import { useContentSize } from "../../../modules/base/hooks/use-content-size";
import { useBreadcrumbs } from "../../../modules/base/hooks/useBreadcrumbs";
import { ContentSizes } from "../../../modules/base/types/ContentSizes";

export type DeviceListPageProps = {}

export default function DeviceListPage({}: DeviceListPageProps) {
    const [t] = useTranslation();
    const [devices, setDevices] = useState<DeviceModelData[]>( [] );
    const { loadUserDevices } = useDeviceApi();
    const [isLoading, setIsLoading] = useState( false );

    useContentSize( ContentSizes.NARROW )

    useBreadcrumbs( [
        { text: t( 'nav.profile.title' ), url: '/users/me' },
        { text: t( 'nav.devices.title' ), url: '/users/me/devices' },
    ] );

    useEffect( () => {
        setIsLoading( true );
        loadUserDevices()
            .then( (list) => {
                setDevices( list );
            } )
            .finally( () => {
                setIsLoading( false );
            } )
    }, [] );

    if (isLoading) {
        return <FullScreenLoader/>;
    }


    return (<div className="DeviceListPage">
        <Card
            avatar={ <Link to={ '/users/me' }>
                <BackButton/>
            </Link> }
            actions={ <Link to={ '/users/me/devices/register' }>
                <TertiaryBtn>
                    { t( 'general.register_new_device' ) }
                </TertiaryBtn>
            </Link> }
            title={ t( 'general.my_devices' ) }>
            <SimpleList list={ devices.map( device => ({
                id: device.deviceUuid,
                label: device.name,
                to: `/users/me/devices/${ device.deviceUuid }`
            }) ) }/>
        </Card>
    </div>);
}
