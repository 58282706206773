import { Box, PrimaryBtn } from '@mondstein/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


export default function LoginSignupWidget() {
    const [t] = useTranslation();

    return (<div>
        <Box mr={ 4 }>
            <Link to={ '/auth/signup' }>
                <PrimaryBtn>
                    { t( 'general.sign_up' ) }
                </PrimaryBtn>
            </Link>
        </Box>

        <Link to={ '/auth/login' }>
            <PrimaryBtn>
                { t( 'general.log_in' ) }
            </PrimaryBtn>
        </Link>
    </div>);
}
