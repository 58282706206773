import { Box, Flex, FormControl, FormLabel, Image, Input } from '@chakra-ui/react';
import { PrimaryBtn, SecondaryBtn } from '@mondstein/ui';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export type RequestResetFormProps = {
    /** Handler when reset btn is clicked */
    onReset: (userName: string) => void,

    /** Flag if the request is pending */
    isLoading?: boolean
};

export default function RequestResetForm({ onReset, isLoading }: RequestResetFormProps) {
    const [userName, setUserName] = useState( '' );
    const [t] = useTranslation();

    const onResetClicked = useCallback( () => {
        onReset( userName )
    }, [userName] );

    const isSubmittable = useMemo( () => {
        return userName && userName.length > 3;
    }, [userName] );

    return (<Box>
        <Flex justifyContent="center">
            <Image
                boxSize="100px"
                objectFit="cover"
                src="/logo_white.svg"
                alt="Mondstein Logo"
            />
        </Flex>

        <FormControl mt={ 8 }>
            <FormLabel>{ t( 'general.email' ) }</FormLabel>
            <Input value={ userName }
                onChange={ (e) => setUserName( e.target.value ) }
                type="email"
                placeholder={ t( 'general.email.placeholder' ) } size="lg"/>
        </FormControl>

        <Flex justifyContent="space-between" mt={ 4 }>
            <Link
                to={ '/' }>
                <SecondaryBtn>
                    { t( 'general.log_in' ) }
                </SecondaryBtn>
            </Link>
            <PrimaryBtn
                isDisabled={ !isSubmittable }
                isLoading={ isLoading }
                onClick={ onResetClicked }>
                { t( 'general.submit' ) }
            </PrimaryBtn>
        </Flex>
    </Box>);
}
