import { Box } from '@chakra-ui/react';
import { Breadcrumbs } from '@mondstein/ui';
import React, { PropsWithChildren, useMemo } from 'react';
import { Link } from "react-router-dom";
import { useBreadcrumbs } from "../../hooks/useBreadcrumbs";
import { ContentSizeDefinition, ContentSizes } from '../../types/ContentSizes';

export type ContainerProps = PropsWithChildren<{
    size: ContentSizes;
    showBreadcumbs?: boolean;
}>;

export default function Container({ size, showBreadcumbs, children }: ContainerProps) {
    const { width } = useMemo( () => {
        return ContentSizeDefinition[size];
    }, [size] );

    const { width: wideWidth } = useMemo( () => {
        return ContentSizeDefinition[ContentSizes.WIDE];
    }, [size] );

    const [breadcrumbs] = useBreadcrumbs();

    return (<>
        <Box
            margin={ '0 auto' }
            maxW={ width } w="100%">

            <Box mb={ 6 } opacity={0.7}>
                <Breadcrumbs breadcrumbs={ breadcrumbs } linkComponent={ Link }/>
            </Box>

            { children }
        </Box></>)
}
