import { useGameApi } from "@mondstein/hooks";
import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../modules/base/components/Container/Container';
import { EmptyState } from '../../modules/base/components/EmptyState/EmptyState';
import { useAsyncData } from "../../modules/base/hooks/use-async-data";
import { usePageTitle } from "../../modules/base/hooks/use-page-title";
import { useBreadcrumbs } from '../../modules/base/hooks/useBreadcrumbs';
import { ContentSizes } from '../../modules/base/types/ContentSizes';
import GameGrid from '../../modules/games/components/GameGrid/GameGrid';

/**
 * Overview page to browse the game library
 * @constructor
 */
export default function OverviewPage() {
    const [t] = useTranslation();
    const { loadList } = useGameApi();
    const [isLoading, games] = useAsyncData( () => loadList() );
    usePageTitle( t( 'general.games_list' ) );

    useBreadcrumbs( [
        { text: t( 'general.store' ), url: '/' },
        { text: t( 'page.list.title' ), url: '/store/games' },
    ] );

    if (!isLoading && games?.length === 0) {
        return <EmptyState title={ t( 'page.list.empty' ) }
            link={ '/' }
            linkLabel={ t( 'error.return_to_home' ) }
            illustration={ 'empty' }/>
    }

    return <Container size={ ContentSizes.WIDE } showBreadcumbs={ true }>
        { games && <GameGrid games={ games }/> }
    </Container>;
}