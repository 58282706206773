import { Box, Flex, Image, Tag } from '@chakra-ui/react';
import { Card, SecondaryBtn } from '@mondstein/ui';
import React, { useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { useFormattedPrice } from '../../../base/hooks/useFormattedPrice';

export type GameCardProps = {
    /** ID of the current game */
    id: number;

    /** URL to the current games visual */
    visualUrl: string;

    /** Name of the game */
    name: string;

    /** Raw text description of the game */
    description: string;

    /** Price of the game in cents */
    price: number;

    /** Currency string */
    currency: string;

    /** Flag if the game is featured */
    is_featured: boolean;
};

const MAX_DESCRIPTION_LENGTH = 180;

/**
 * Card component to visualize a game object from the API
 * @constructor
 */
export const GameCard = ({ id, visualUrl, name, description, currency, price, is_featured }: GameCardProps) => {
    const formattedPrice = useFormattedPrice( price, currency );
    const [t] = useTranslation();

    const cuttedText = useMemo( () => {
        if (description.length <= MAX_DESCRIPTION_LENGTH) {
            return description;
        }
        const text = description.substr( 0, MAX_DESCRIPTION_LENGTH );
        return `${ text }…`;
    }, [description] );


    return (
        <Card
            title={ name }
            headerVisual={ <Image
                loading="lazy"
                src={ visualUrl }
                width={ '100%' }
                height="300px"
                objectFit="cover"
            /> }>
            <Flex flexDirection={ 'column' } flexGrow={ 1 }>
                <Flex justifyContent="space-between">
                    <Box/>
                    { is_featured && <Tag colorScheme={ 'brand' }>Featured</Tag> }
                </Flex>

                <Flex flexGrow={ 1 } mt={ 4 } mb={ 4 }>
                    { cuttedText }
                </Flex>

                <Flex justifyContent="space-between">
                    <Tag colorScheme={ 'brand' }>{ formattedPrice }</Tag>

                    <Link to={ `/store/games/${ id }` }>
                        <SecondaryBtn>
                            { t( 'general.more' ) }
                        </SecondaryBtn>
                    </Link>
                </Flex>
            </Flex>
        </Card>
    );
}

export default GameCard;
