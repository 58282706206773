import { Grid, Image, Text } from '@chakra-ui/react';
import { GameModelData } from "@mondstein/api";
import { useGameApi } from "@mondstein/hooks";
import { Box, Card } from "@mondstein/ui";
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import Container from '../../../modules/base/components/Container/Container';
import { FullScreenLoader } from '../../../modules/base/components/FullScreenLoader';
import { useAsyncData } from "../../../modules/base/hooks/use-async-data";
import { usePageTitle } from "../../../modules/base/hooks/use-page-title";
import { useBreadcrumbs } from '../../../modules/base/hooks/useBreadcrumbs';
import { useFormattedPrice } from '../../../modules/base/hooks/useFormattedPrice';
import { ContentSizes } from '../../../modules/base/types/ContentSizes';
import MetaDataCard from '../../../modules/games/components/MetaDataCard/MetaDataCard';
import PublisherCard from '../../../modules/games/components/PublisherCard/PublisherCard';
import { useIsGamePurchased } from '../../../modules/games/hooks/use-is-game-purchased';

export type DetailPageParams = {
    id: string;
}

/**
 * Detail page for a game
 * @constructor
 */
export default function DetailPage() {
    const [t] = useTranslation();
    const { id } = useParams<DetailPageParams>();
    const { load } = useGameApi();
    const [isLoading, game] = useAsyncData( () => {
        if (!id) {
            return null;
        }
        return load( parseInt( id ) )
    }, [id] );
    const setPageTitle = usePageTitle();

    const CTA = ({ game }: { game: GameModelData }) => {
        const price = useFormattedPrice( game.price, game.currency );

        const [isPurchased, isLoading] = useIsGamePurchased( game.id );
        if (isLoading) {
            return <FullScreenLoader/>
        }

        if (isPurchased) {
            return <Text>Game already purchased</Text>;
        }

        return <Link to={ `/store/checkout/${ game.id }` }>
            Get it for { price }
        </Link>
    }

    useEffect( () => {
        if (game) {
            setPageTitle( game.name )
        }
    }, [game] );

    const Content = ({ game }: { game: GameModelData }) => {
        useBreadcrumbs( [
            { text: t( 'general.store' ), url: '/' },
            { text: t( 'general.games' ), url: '/store/games' },
            { text: game.name, url: `/store/games/${ game.id }` },
        ], [] );

        return (<>
            <Container size={ ContentSizes.WIDE } showBreadcumbs={ true }>
                <Image src={ game.primaryVisual }
                    borderWidth={ '1px' }
                    boxShadow={ '2xl' }
                    width={ '100%' }
                    maxHeight={ '400px' }
                    objectFit={ 'cover' }
                    alt={ 'Game Primary Visual' }/>
            </Container>
            <Box mt={ 4 }>
                <Container size={ ContentSizes.NARROW }>
                    <Grid templateColumns="8fr 4fr" gap={ 12 }>
                        <Box>
                            <Card>
                                <div dangerouslySetInnerHTML={ { __html: game.description } }/>
                            </Card>
                        </Box>

                        <Box>
                            <Box mb={ 4 }>
                                <PublisherCard name={ game.publisher.name }
                                    avatarUrl={ game.publisher.avatarUrl }
                                    createdAt={ game.publisher.createdAt }/>
                            </Box>
                            <Box mb={ 4 }>
                                <MetaDataCard/>
                            </Box>
                            <CTA game={ game }/>
                        </Box>
                    </Grid>
                </Container>
            </Box>
        </>)
    };

    if (isLoading) {
        return <FullScreenLoader/>
    }

    return (
        <Box>
            { game && <Content game={ game }/> }
        </Box>
    );
}

