import { Box, Button, Flex } from "@chakra-ui/react";
import { PrimaryBtn } from "@mondstein/ui";
import React, { PropsWithChildren, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export type FormActionsProps = PropsWithChildren<{
    abortUrl?: string;
    onSubmit: Function;
    isSubmittable?: boolean;
    submitLabel?: string;
    abortLabel?: string;
}>;

export default function FormActions({
                                        abortUrl,
                                        onSubmit,
                                        isSubmittable,
                                        submitLabel,
                                        abortLabel,
                                        children
                                    }: FormActionsProps) {
    const [t] = useTranslation();
    const onSubmitClicked = useCallback( () => {
        onSubmit();
    }, [onSubmit] )

    return (
        <Flex justifyContent={ 'space-between' }>
            <Box>
                { children }
            </Box>
            <Flex justifyContent="flex-end">
                { abortUrl ?
                    <Button as={ Link } variant="link" mr={ 2 } to={ abortUrl }>
                        { abortLabel || t( 'general.abort' ) }
                    </Button> : <Box/> }
                <PrimaryBtn
                    onClick={ onSubmitClicked }
                    disabled={ !isSubmittable }>
                    { submitLabel || t( 'general.submit' ) }
                </PrimaryBtn>
            </Flex>
        </Flex>);
}
