import { useToast } from "@chakra-ui/react";
import { usePublisherApi } from "@mondstein/hooks";
import { BackButton, Card } from "@mondstein/ui";
import { AxiosError } from "axios";
import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useBreadcrumbs } from "../../../modules/base/hooks/useBreadcrumbs";
import PublisherCreateForm from "../../../modules/studio/components/PublisherCreateForm/PublisherCreateForm";

export type CreatePublisherPageProps = {}

export default function PublisherCreatePage({}: CreatePublisherPageProps) {
    const toast = useToast();
    const [t] = useTranslation();
    const { create } = usePublisherApi();

    useBreadcrumbs( [
        { text: t( 'general.studio' ), url: '/studio' },
        { text: t( 'nav.publisher_list.title' ), url: '/studio/publisher' },
        { text: t( 'general.publisher_create' ), url: `/studio/publisher/create` },
    ] );

    const onPublisherCreate = (name: string) => {
        create( name )
            .then( () => {
                toast( {
                    title: t( 'studio.publisher_created.title' ),
                    description: t( 'studio.publisher_created.description' ),
                    status: "success"
                } )
            } )
            .catch( (e: AxiosError) => {
                if (e.isAxiosError && e.response?.data.statusCode === 409) {
                    toast( {
                        title: t( 'studio.publisher_create_failed.title' ),
                        description: t( 'general.api_error.not_unique' ),
                        status: "error"
                    } )
                    return;
                }
                toast( {
                    title: t( 'studio.publisher_create_failed.title' ),
                    description: t( 'studio.publisher_create_failed.description' ),
                    status: "error"
                } )
            } )
    };

    return (<Card title={ t( 'general.publisher_create' ) }
        avatar={ <Link to={ `/studio/publisher` }><BackButton/></Link> }>
        <PublisherCreateForm onCreate={ onPublisherCreate }/>
    </Card>);
}
