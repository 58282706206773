import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { contentSizeState } from '../state/content-size';
import { ContentSizes } from '../types/ContentSizes';

export const useContentSize = (size: ContentSizes) => {
    const setContentSize = useSetRecoilState( contentSizeState );

    useEffect( () => {
        setContentSize( size );
        return () => {
            setContentSize( ContentSizes.DEFAULT );
        }
    }, [size] )
}
