import * as React from 'react';
import { CustomRoute } from '../../router/routes';
import DeviceDetailPage from "./DeviceDetailPage/DeviceDetailPage";
import DeviceListPage from "./DeviceListPage/DeviceListPage";
import Main from './Main/Main';
import { RegisterDevicePage } from "./RegisterDevicePage/RegisterDevicePage";

export const deviceRoutes: CustomRoute[] = [
    {
        path: '/users/me/devices',
        element: <Main/>,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '/users/me/devices',
                element: <DeviceListPage/>
            },
            {
                path: '/users/me/devices/register',
                element: <RegisterDevicePage/>
            },
            {
                path: '/users/me/devices/:uuid',
                element: <DeviceDetailPage/>
            },
        ]
    },
];
