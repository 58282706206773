import { useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { BreadcrumbItem } from "@mondstein/ui";
import { breadcrumbState } from '../state/breadcrumbs';

/**
 * Hook to set the breadcrumbs in the state
 */
export const useBreadcrumbs = (path?: (BreadcrumbItem | boolean)[], deps: any[] = []): [BreadcrumbItem[], (p: BreadcrumbItem[]) => void] => {
    const [breadcrumbs, setBreadcrumbs] = useRecoilState( breadcrumbState );

    const filteredPath = useMemo( () => {
        if (!path) {
            return [];
        }

        return path.filter( item => !!item );
    }, [path] );

    useEffect( () => {
        // @ts-ignore
        setBreadcrumbs( filteredPath );
    }, [...deps] )

    return [breadcrumbs, setBreadcrumbs];
}
