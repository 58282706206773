import { Box, Card, Stats } from '@mondstein/ui';
import React from 'react';
import { useFormattedDate } from '../../../base/hooks/useFormattedDate';
import { useFormattedNumber } from '../../../base/hooks/useFormattedNumber';

/**
 * Shows some insights about a game
 * @constructor
 */
export const MetaDataCard = () => {
    const date = useFormattedDate( new Date( '2021-01-01' ) );
    const installs = useFormattedNumber( 10_000 );
    return <Card>
        <Stats title={ 'Published' } content={ date }/>
        <Box mt={ 4 }>
            <Stats title={ 'Published' } content={ date }/>
        </Box>

        <Box mt={ 4 }>
            <Stats title={ 'Installs' } content={ installs }/>
        </Box>
        <Box mt={ 4 }>
            <Stats title={ 'Genre' } content={ 'Unknown' }/>
        </Box>
    </Card>
}

export default MetaDataCard;
