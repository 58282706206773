import * as React from 'react';
import { CustomRoute } from '../../router/routes';
import DashboardPage from './DashboardPage';

export const dashboardRoutes: CustomRoute[] = [
    {
        path: '/',
        element: <DashboardPage/>,
        meta: {
            requiresAuth: true,
        },
    },
];
