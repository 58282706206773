import { useMemo } from 'react';
import { CURRENT_LOCALE } from '../../../config/locale';

/**
 * Format a number for the current locale
 */
export const useFormattedNumber = (number: number) => {
    return useMemo( () => {
        return new Intl.NumberFormat( CURRENT_LOCALE, { style: 'decimal' } ).format( number )
    }, [number] );
}
