import { Box, Divider, Flex, Heading } from '@chakra-ui/react';
import { BackButton } from '@mondstein/ui';
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

export type PageHeadingProps = PropsWithChildren<{
    backLink?: string;
    title: string;
}>;

export default function PageHeading({ backLink, title, children }: PageHeadingProps) {
    return (<Box>
        <Flex alignItems={ 'center' } justifyContent={ 'space-between' }>
            <Flex>
                { backLink &&
                <Link to={ backLink }>
                    <BackButton/>
                </Link>
                }
                <Heading ml={ 4 } size={ 'xl' } mb={ 4 }>{ title }</Heading>
            </Flex>
            { children }
        </Flex>
        <Divider mb={ 4 }/>
    </Box>);
}
