import { Box, Flex, useToast } from '@chakra-ui/react';
import { useAuthenticationApi } from "@mondstein/hooks";
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router";
import { Navigate, useParams } from 'react-router-dom';

import ResetForm from '../../modules/auth/components/ResetForm/ResetForm';


export type PasswordRestorePageParams = {
    token: string;
}

export function PasswordRestorePage() {
    const [isSaving, setIsSaving] = useState( false );
    const { token } = useParams<PasswordRestorePageParams>();
    const toast = useToast();
    const [t] = useTranslation();
    const navigate = useNavigate();
    const { resetPassword, verifyResetToken } = useAuthenticationApi();

    useEffect( () => {
        if (!token) {
            return;
        }

        verifyResetToken( token )
            .then( (isValid) => {
                if (isValid) {
                    return;
                }
                toast( {
                    title: t( 'reset.token.invalid.title' ),
                    description: t( 'reset.token.invalid.description' ),
                    status: 'error',
                } )
            } );
    }, [token] );

    const saveNewPassword = useCallback( (password: string) => {
        if (!token) {
            return;
        }

        setIsSaving( true );
        resetPassword( token, password )
            .then( () => {
                toast( {
                    title: t( 'reset.pw.reset.succeeded.title' ),
                    description: t( 'reset.pw.reset.succeeded.description' ),
                    status: 'success',
                } )
                navigate( '/' );
            } )
            .catch( () => {
                toast( {
                    title: t( 'reset.pw.reset.failed.title' ),
                    description: t( 'reset.pw.reset.failed.description' ),
                    status: 'error',
                } )
            } )
            .finally( () => {
                setIsSaving( false );
            } )
    }, [token] );

    if (!token) {
        return <Navigate to={ '/' } replace/>;
    }

    return (<Flex w="100vw" h="100vh" align="center" justifyContent="center">
        <Box maxW={ 400 } w={ '100%' }>
            <ResetForm isLoading={ isSaving } onSave={ saveNewPassword }/>
        </Box>
    </Flex>);
}

export default PasswordRestorePage;
