import { Flex, FormControl, FormLabel, Input, useToast } from "@chakra-ui/react";
import { useDeviceApi } from "@mondstein/hooks";
import { BackButton, Card, PrimaryBtn } from "@mondstein/ui";
import * as React from "react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useBreadcrumbs } from "../../../../modules/base/hooks/useBreadcrumbs";

export const CreateDevicePage = () => {
    const [deviceName, setDeviceName] = useState( '' );
    const [isLoading, setLoading] = useState( false );
    const toast = useToast();
    const [t] = useTranslation();
    const { createNewDevice } = useDeviceApi();

    useBreadcrumbs( [
        { text: t( 'general.admin' ), url: '/admin' },
        { text: t( 'admin.devices.list' ), url: '/admin/devices' },
        { text: t( 'admin.devices.new_device' ), url: '/admin/devices/new' },
    ] );

    const onCreate = useCallback( () => {
        setLoading( true );
        createNewDevice( deviceName )
            .then( () => {
                toast( {
                    title: t( 'device.create_successfully' ),
                    description: t( 'device.linked.success.description' ),
                    status: 'success',
                } );
            } )
            .catch( () => {
                toast( {
                    title: t( 'device.create_failed' ),
                    description: t( 'device.linked.failed.description' ),
                    status: 'error',
                } );
            } )
            .finally( () => {
                setLoading( false );
            } );
    }, [deviceName] );

    return (<Card
        avatar={ <Link to={ '/admin/devices' }><BackButton/></Link> }
        title={ t( 'device.create_device' ) }>
        <FormControl>
            <FormLabel>{ t( 'device.create_device' ) }</FormLabel>
            <Input value={ deviceName }
                placeholder={ 'Device name' }
                onChange={ (e) => setDeviceName( e.target.value ) }
                size="lg"/>
        </FormControl>

        <Flex justifyContent={ 'flex-end' } mt={ 6 }>
            <PrimaryBtn disabled={ !deviceName } onClick={ onCreate } isLoading={ isLoading }>
                { t( 'general.create' ) }
            </PrimaryBtn>
        </Flex>
    </Card>);
}