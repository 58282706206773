import { Flex, Heading, Image } from '@chakra-ui/react';
import { Box, LoginComponent, SecondaryBtn, TertiaryBtn } from "@mondstein/ui";
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export type LoginFormProps = {
    /**
     * Callback when the login btn is clicked
     */
    onLogin: (username: string, password: string) => void,

    /** define if the request is pending */
    isLoading?: boolean
};

export default function LoginForm({ onLogin, isLoading }: LoginFormProps) {
    const [userName, setUserName] = useState( '' );
    const [password, setPassword] = useState( '' );
    const [t] = useTranslation()

    const onSubmit = useCallback( (u: string, p: string) => {
        onLogin( userName, password )
    }, [userName, password] );

    const onChange = useCallback( (u: string, p: string) => {
        setUserName( u )
        setPassword( p )
    }, [] );

    return (<Box>
        <Flex justifyContent="center" alignItems={ 'center' } mb={ 10 }>
            <Image
                boxSize="80px"
                objectFit="cover"
                src="/logo_white.svg"
                alt="Mondstein Logo"
            />
            <Heading fontSize={ '64px' } ml={ 20 } letterSpacing={ '4px' } as={ 'h2' } fontWeight={ 'bold' }
                fontFamily={ 'Blanka Regular' }>mondstein</Heading>
        </Flex>

        <LoginComponent onChange={ onChange }
            onSubmit={ onSubmit }
            isLoading={ isLoading }
            submitLabel={ t( 'general.log_in' ) }
            tertiaryAction={ <Link to={ '/auth/reset' }>
                <TertiaryBtn>
                    { t( 'general.reset_password' ) }
                </TertiaryBtn>
            </Link> }
            secondaryAction={ <Link to={ '/auth/signup' }>
                <SecondaryBtn>
                    { t( 'general.sign_up' ) }
                </SecondaryBtn>
            </Link> }/>
    </Box>);
}
