import { Box, Stack } from '@chakra-ui/react';
import { useGameApi } from "@mondstein/hooks";
import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../modules/base/components/Container/Container';
import { FullScreenLoader } from "../../modules/base/components/FullScreenLoader";
import { useAsyncData } from "../../modules/base/hooks/use-async-data";
import { useBreadcrumbs } from '../../modules/base/hooks/useBreadcrumbs';
import AppLayout from "../../modules/base/layouts/AppLayout";
import { ContentSizes } from '../../modules/base/types/ContentSizes';
import { GamesTeaser } from '../../modules/games/components/GamesTeaser/GamesTeaser';
import WelcomeMessage from '../../modules/user/components/WelcomeMessage/WelcomeMessage';

/**
 * Dashboard page showing the first screen of the application with recommendation, highlights and so on
 */
export default function DashboardPage() {
    const [t] = useTranslation();
    const { loadFeaturedList } = useGameApi();
    const [isLoading, featuredGames] = useAsyncData( () => loadFeaturedList() );

    useBreadcrumbs( [
        { text: t( 'general.store' ), url: '/' },
    ] );

    return <AppLayout>
        <Container size={ ContentSizes.WIDE }>
            <Box mb={ 24 }>
                <WelcomeMessage/>
            </Box>

            { isLoading && <FullScreenLoader/> }
            { featuredGames &&
            <Stack direction={ 'column' } spacing={ 40 }>
                <GamesTeaser title="Weeklies Special" games={ featuredGames } limit={ 4 }/>
                <GamesTeaser title="Editor Picks" games={ featuredGames } limit={ 4 }/>
                <GamesTeaser title="Top Trending" games={ featuredGames } limit={ 8 }/>
            </Stack> }
        </Container>
    </AppLayout>;
}