import * as React from 'react';
import { CustomRoute } from '../../router/routes';
import CheckoutItemPage from "./CheckoutItemPage/CheckoutItemPage";
import CheckoutListPage from "./CheckoutListPage/CheckoutListPage";
import CheckoutPage from "./CheckoutPage/CheckoutPage";
import Main from './Main/Main';

export const checkoutRoutes: CustomRoute[] = [
    {
        path: '/store/checkout',
        element: <Main/>,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: 'purchase/:id',
                element: <CheckoutItemPage/>
            },
            {
                path: '',
                element: <CheckoutListPage/>
            },
            {
                path: ':id',
                element: <CheckoutPage/>
            },
        ]
    },
];
