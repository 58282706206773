import { Flex, Grid, Heading, Img } from '@chakra-ui/react';
import React from 'react';
import { useFormattedPrice } from '../../../base/hooks/useFormattedPrice';

export type CheckoutSummaryProps = {
    /** Thumbnail shown in list for product */
    thumbnail: string;

    /** Title of the produce */
    title: string;

    /** The price of the product in cents */
    price: number;

    /** ISO Code of the currency */
    currency: string;
}

export default function CheckoutSummary({ thumbnail, title, price, currency }: CheckoutSummaryProps) {
    const formattedPrice = useFormattedPrice( price, currency );

    return (
        <Grid className="checkout-summary"
              gridTemplateAreas={ 'thumbnail name price' }
              gridTemplateColumns={ '160px auto 1fr' }
              gridGap={ 5 }
              alignItems={ 'center' }>
            <Img src={ thumbnail } height={ 20 } width={ 40 } objectFit={ 'cover' }/>
            <Heading variant={ 'h3' }>
                { title }
            </Heading>
            <Flex justifyContent={ 'flex-end' }>{ formattedPrice }</Flex>
        </Grid>);
}
