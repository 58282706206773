import { FormControl, FormErrorMessage, FormLabel, Input, VStack } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';

export type PasswordRepeatProps = {
    /** Callback handler if the user changes his password */
    onChange: (password: string) => void;
    /** Callback if the entered passwords are valid */
    onIsValidChanged: (isValid: boolean) => void
};

/**
 * Component to enter the same password twice
 * @constructor
 */
export const PasswordRepeat = ({ onChange, onIsValidChanged }: PasswordRepeatProps) => {
    const [newPw, setNewPw] = useState( '' );
    const [repeatNewPw, setRepeatNewPw] = useState( '' );

    const doNewPwMatch = useMemo( () => {
        return newPw === repeatNewPw && newPw.length >= 8;
    }, [newPw, repeatNewPw] );

    useEffect( () => {
        onIsValidChanged( doNewPwMatch )
        onChange( newPw );
    }, [newPw, doNewPwMatch] );

    return (
        <VStack
            spacing={ 8 }
            align="stretch">
            <FormControl>
                <FormLabel>Password</FormLabel>
                <Input value={ newPw }
                       onChange={ (e) => setNewPw( e.target.value ) }
                       type="password"
                       size="lg"/>
            </FormControl>

            <FormControl isInvalid={ !doNewPwMatch && newPw.length > 0 }>
                <FormLabel>Repeat Password</FormLabel>
                <Input value={ repeatNewPw }
                       onChange={ (e) => setRepeatNewPw( e.target.value ) }
                       type="password"
                       size="lg"/>
                { !doNewPwMatch && <FormErrorMessage>Passwords do not match!</FormErrorMessage> }
            </FormControl>
        </VStack>
    );
}
