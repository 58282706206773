import { PublisherModelData } from "@mondstein/api";
import { usePublisherApi } from "@mondstein/hooks";
import { StatusBadge } from "@mondstein/ui";
import React from 'react';
import SimpleList from "../../../base/components/SimpleList/SimpleList";
import SimpleListSkeleton from "../../../base/components/SimpleListSkeleton/SimpleListSkeleton";
import { useAsyncData } from "../../../base/hooks/use-async-data";

export type PublisherGameListProps = {
    publisher: PublisherModelData
}

const MAP = {
    'DRAFT': 'DEFAULT',
    'REVIEWING': 'WARNING',
    'PUBLISHED': 'SUCCESS',
    'DELETED': 'ERROR',
}

export default function PublisherGameList({ publisher }: PublisherGameListProps) {
    const { loadGames } = usePublisherApi();
    const [isLoading, list] = useAsyncData( () => loadGames( publisher.id ), [publisher] );

    if (isLoading) {
        return <SimpleListSkeleton/>;
    }

    return (<SimpleList list={ (list || []).map( (item) => ({
        id: item.id,
        label: item.name,
        to: `/studio/games/${ item.id }`,
        // @ts-ignore
        secondary: <StatusBadge status={ MAP[item.status] }>{ item.status }</StatusBadge>
    }) ) }/>);
}
