import * as React from 'react';
import { CustomRoute } from "../../router/routes";
import { CreateDevicePage } from "./device/create-device-page/create-device-page";
import { DeviceDetailPage } from "./device/device-detail-page/device-detail-page";
import { DeviceEditPage } from "./device/device-edit-page";
import DevicesListPage from "./device/devices-list-page/devices-list-page";
import { MainPage } from "./main-page/main-page";

export const adminRoutes: CustomRoute[] = [
    {
        path: '/admin',
        element: <MainPage/>,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '/admin/devices/new',
                element: <CreateDevicePage/>
            },
            {
                path: '/admin/devices/:id/edit',
                element: <DeviceEditPage/>
            },
            {
                path: '/admin/devices/:id',
                element: <DeviceDetailPage/>
            },
            {
                path: '/admin/devices',
                element: <DevicesListPage/>
            },
        ]
    },
]