import { BackButton, Card } from '@mondstein/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useBreadcrumbs } from '../../../modules/base/hooks/useBreadcrumbs';
import PurchaseListItem from '../../../modules/checkout/components/PurchaseListItem/PurchaseListItem';
import { usePurchase } from '../../../modules/checkout/hooks/use-purchase';

export type CheckoutItemPageParams = {
    id: string;
}

export default function CheckoutItemPage() {
    const [t] = useTranslation();
    const { id } = useParams<CheckoutItemPageParams>();
    const [purchase] = usePurchase( id ? parseInt( id ) : null );

    useBreadcrumbs( [
        { text: t( 'general.store' ), url: '/' },
        { text: t( 'nav.purchase_list.title' ), url: '/store/checkout' },
        { text: `${ t( 'nav.purchase_item.title' ) } #${ id }`, url: `/store/checkout/purchase/${ id }` },
    ] );

    return (<Card avatar={ <Link to={ '/store/checkout' }><BackButton/></Link> }
        title={ `${ t( 'nav.purchase_item.title' ) } #${ id }` }>
        { !!purchase && <PurchaseListItem purchaseModel={ purchase }/> }
    </Card>);
}
