import { useCheckoutApi } from "@mondstein/hooks";
import { Card } from "@mondstein/ui";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FullScreenLoader } from '../../../modules/base/components/FullScreenLoader';
import { useAsyncData } from "../../../modules/base/hooks/use-async-data";
import { useBreadcrumbs } from '../../../modules/base/hooks/useBreadcrumbs';
import PurchaseList from '../../../modules/checkout/components/PurchaseList/PurchaseList';

export function CheckoutListPage() {
    const [t] = useTranslation();
    const { getMyPurchases } = useCheckoutApi()
    const [isLoading, purchases] = useAsyncData( () => getMyPurchases() );

    useBreadcrumbs( [
        { text: t( 'general.store' ), url: '/' },
        { text: t( 'nav.purchase_list.title' ), url: '/store/checkout' },
    ] );

    return (<>
        <Card title={ t( 'user.purchase_list' ) }>
            { isLoading && <FullScreenLoader/> }
            <PurchaseList purchases={ purchases ?? [] }/>
        </Card>
    </>);
}

export default CheckoutListPage;
