import { PurchaseModelData } from "@mondstein/api";
import { useMemo } from 'react';

export const getDataFromGamePurchaseModel = (purchase: PurchaseModelData) => {
    const { name, primaryVisual } = JSON.parse( purchase.serializedEntity );
    return { name, primaryVisual };
}

export const useTransformedPurchaseModelData = (modelData: PurchaseModelData) => {
    const { title, image } = useMemo( () => {
        if (modelData.type === 'game') {
            const { name, primaryVisual } = getDataFromGamePurchaseModel( modelData );
            return {
                title: name,
                image: primaryVisual,
            };
        }
        throw new Error( 'Type not implemented' );
    }, [modelData] );

    return { title, image };
}
