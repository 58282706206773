import { FormControl, FormLabel, Switch, useColorMode } from "@chakra-ui/react";
import { UserConfig } from "@mondstein/api";
import { useToast, useUserApi } from "@mondstein/hooks";
import { BackButton, Card } from '@mondstein/ui';
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { FullScreenLoader } from "../../../modules/base/components/FullScreenLoader";
import { useAsyncData } from "../../../modules/base/hooks/use-async-data";

export const UserSettingsPage = () => {
    const [t] = useTranslation();
    const { colorMode, toggleColorMode } = useColorMode();
    const [, setUserConfig] = useState<UserConfig>( { uiMode: 'dark' } );
    const { updateUserConfig, getUserConfig } = useUserApi();
    const [isLoading, remoteUserConfig] = useAsyncData( () => getUserConfig() );
    const toast = useToast();

    useEffect( () => {
        if (!remoteUserConfig) {
            return;
        }
        setUserConfig( remoteUserConfig );
    }, [remoteUserConfig] );

    useEffect( () => {
        updateUserConfig( { ...remoteUserConfig, uiMode: colorMode } )
            .then( () => {
                setUserConfig( { ...remoteUserConfig, uiMode: colorMode } );
                toast.success( t( 'user.config.save_successfully' ) );
            } )
            .catch( () => {
                toast.error( t( 'user.config.save_failed' ) );
            } );
    }, [colorMode, remoteUserConfig] );

    return <>
        <Card
            title={ t( `general.settings` ) }
            avatar={ <Link to={ '/users/me' }><BackButton/></Link> }
        >
            { isLoading && <FullScreenLoader/> }
            <FormControl>
                <FormLabel>{ t( 'settings.light_dark_switch' ) }</FormLabel>
                <Switch disabled={ isLoading } isChecked={ colorMode === 'light' } onChange={ toggleColorMode }/>
            </FormControl>
        </Card>
    </>;
}