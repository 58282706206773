import { Box, FormControl, FormLabel, Input } from "@chakra-ui/react";
import React, { useCallback, useState } from 'react';
import { useTranslation } from "react-i18next";
import FormActions from "../../../base/components/FormActions/FormActions";

export type CreatePublisherFormProps = {
    onCreate: (name: string) => void;
}

export default function PublisherCreateForm({ onCreate }: CreatePublisherFormProps) {
    const [name, setName] = useState( '' );
    const [t] = useTranslation();

    const onSubmit = useCallback( () => {
        onCreate( name )
    }, [name, onCreate] );

    return (<Box>
        <FormControl mt={ 8 }>
            <FormLabel>{ t( 'general.name' ) }</FormLabel>
            <Input value={ name }
                   onChange={ (e) => setName( e.target.value ) }
                   type="text"
                   placeholder={ t( 'general.name.placeholder' ) } size="lg"/>
        </FormControl>

        <Box mt={ 4 }>
            <FormActions onSubmit={ onSubmit } isSubmittable={ !!name } submitLabel={t('general.create')}/>
        </Box>
    </Box>);
}
