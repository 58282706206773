import { Avatar, } from '@chakra-ui/react';
import { UserInfoData } from "@mondstein/api";
import { Card, Flex, Stats } from '@mondstein/ui';
import React from 'react';

export type UserProfileCardProps = {
    /** Info object fetched from the API */
    userInfo: UserInfoData;
}

/**
 * Showing a users profile
 * @constructor
 */
export function UserProfileCard({ userInfo }: UserProfileCardProps) {
    return <Card title={ 'Profile' }>
        <Flex>
            <Stats title={ 'Username' } content={ userInfo.username }/>
            <Avatar name={ userInfo.username }/>
        </Flex>
    </Card>
}
