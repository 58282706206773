import { useAuthenticationApi } from "@mondstein/hooks";
import { BackButton, Card } from "@mondstein/ui";
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom';
import { useBreadcrumbs } from '../../../modules/base/hooks/useBreadcrumbs';
import { ChangePassword } from '../../../modules/user/components/ChangePassword/ChangePassword';

export type ChangePasswordPageProps = {}

export default function ChangePasswordPage({}: ChangePasswordPageProps) {
    const navigate = useNavigate();
    const [t] = useTranslation();
    const { doLogout } = useAuthenticationApi();

    useBreadcrumbs( [
        { text: t( 'general.store' ), url: '/' },
        { text: t( 'nav.profile.title' ), url: '/users/me' },
        { text: t( 'general.change_password' ), url: '/users/me/change-password' },
    ] );

    const onPasswordChanged = useCallback( () => {
        doLogout()
            .then( () => {
                navigate( '/' )
            } )
    }, [] );

    return (<Card avatar={ <Link to={ `/users/me` }><BackButton/></Link> }
        title={ t( `general.change_password` ) }>
        <ChangePassword onPasswordChanged={ onPasswordChanged }/>
    </Card>);
}
