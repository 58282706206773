export enum ContentSizes {
    NARROW = 'narrow',
    WIDE = 'wide',
    DEFAULT = 'default',
    FULL = 'full',
}


export const ContentSizeDefinition = {
    [ContentSizes.NARROW]: { width: '960px', padding: 4 },
    [ContentSizes.WIDE]: { width: '1440px', padding: 4 },
    [ContentSizes.DEFAULT]: { width: '1440px', padding: 4 },
    [ContentSizes.FULL]: { width: '100%', padding: 8 },
}
