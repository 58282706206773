import { useToast } from '@chakra-ui/react';
import { useAuthenticationApi } from "@mondstein/hooks";
import { AxiosError } from 'axios';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SignupForm from '../../../modules/auth/components/SignupForm/SignupForm';
import Container from "../../../modules/base/components/Container/Container";
import AppLayout from '../../../modules/base/layouts/AppLayout';
import { ContentSizes } from "../../../modules/base/types/ContentSizes";

export default function SignupPage() {
    const [isLoading, setIsLoading] = useState( false );
    const toast = useToast();
    const [t] = useTranslation();
    const { signupUser } = useAuthenticationApi();

    const onSignup = useCallback( (username, email) => {
        setIsLoading( true );

        signupUser( username, email )
            .catch( (err: AxiosError) => {
                if (!err.isAxiosError || !err.response?.data.error) {
                    toast( {
                        title: t( 'general.unknown_error.title' ),
                        description: t( 'general.unknown_error.description' ),
                        status: 'error',
                    } );
                }

                toast( {
                    title: err.response?.data.error,
                    description: err.response?.data.message,
                    status: 'error',
                } );
            } )
            .finally( () => {
                setIsLoading( false );
            } );

    }, [] );

    return (<AppLayout>
        <Container size={ ContentSizes.NARROW }>
            <SignupForm onSignup={ onSignup } isLoading={ isLoading }/>
        </Container>
    </AppLayout>);
}
