import { useMemo } from 'react';
import { CURRENT_LOCALE } from '../../../config/locale';
import { formatDate } from "../../../utils/date-formatter";

/**
 * hook to format a date object into a date and time format for the current locale
 */
export const useFormattedDateTime = (date: Date | undefined | string | null, style: "medium" | "full" | "long" | "short" | undefined = 'medium', timeStyle: "medium" | "full" | "long" | "short" | undefined = 'medium') => {
    return useMemo( () => {
        if (!date) {
            return null;
        }

        return formatDate( date, style, timeStyle );
    }, [date] );
}
